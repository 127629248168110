import React, { Component } from 'react';
import Slider from "react-slick";
import Feedback from './feedback';
import FeedbackDetail from './feedbackDetail';
import AO from '../../images/competencies/AO_big.png'
import CM from '../../images/competencies/CM_big.png'
import CO from '../../images/competencies/CO_big.png'
import DO from '../../images/competencies/DO_big.png'
import EI from '../../images/competencies/EI_big.png'
import ST from '../../images/competencies/ST_big.png'
import TC from '../../images/competencies/TC_big.png'
import TL from '../../images/competencies/TL_big.png'
import {getFeedback} from '../../reducers/interview'
import {REQUEST_USER} from '../../reducers/session'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { from } from 'rxjs';


const mapImages = {
	AO: AO,
	CM: CM,
	CO: CO,
	DO: DO,
	EI: EI,
	ST: ST,
	TC: TC,
	TL: TL
}

class FeedbackManager extends Component {
	constructor(props){
		super(props);

	}
	componentDidMount() {
		this.props.userAction.REQUEST_USER()
		this.props.interviewAction.getFeedback()
	}

	render() {		
		const {feedback} = this.props;
		// Change the swipe key to rebuild the swiper
		let swipeKey = feedback.size > 0;
		return (
			<div style={{flex:1,flex:"auto", width:"100%", height:"100%"}}>
				<Slider
					ref={swiper=> this.swiper = swiper} 					
					centerMode={true}
					slidesToShow={swipeKey?3:1}
					afterChange={this.chooseItem}
					arrows={false}>
					{this.getFeedbackPages()}									
				</Slider>
			</div>
		);
	}
	getImage(id) {
		let image =  mapImages[id] 

		if (!image) {
			return TL;
		}
		return image;
	}
	goToSlide(id) {
		if (id=='home') {
			this.goTo('home')
		}else{
			this.swiper.slickGoTo(id+1);
		}
	}
	goTo(name,payload) {
		const { navigate } = this.props.navigation;
		navigate(name, payload);
	}
	getFeedbackPages() {
		const {feedback, score, avatar, name, firstName, personalityText} = this.props;
		let children = []
		children.push(
				<Feedback key="main" personalityText={personalityText} avatar={avatar} name={name} goTo={(id)=> this.goToSlide(id)} feedback={feedback} score={score}/>
			
		);

		feedback.forEach( (f,i)=>{		
			children.push(
				<FeedbackDetail key={f.get("id")+i}  name={firstName} goTo={(id)=> this.goToSlide(id)} image={this.getImage(f.get("id"))} text={f.get("title")} description={f.get("description")}/>
			)		
		});		

		return children;

	}
}


const mapStateToProps = (state) => {

	return {
		selectedInterview: state.interview.get("selectedInterview"),
		feedback: state.interview.get("feedback"),
		score: state.interview.get("score"),
		avatar: state.session.getIn(["profile","image_url"]),
		name: state.session.getIn(["profile","first_name"] ) + " " +state.session.getIn(["profile","last_name"] ),
		firstName: state.session.getIn(["profile","first_name"] ),
		personalityText: state.interview.get("personalityText"),

		
	};
}
  
const mapDispatchToProps = (dispatch) => {
	return {
		interviewAction: bindActionCreators({getFeedback}, dispatch),
		userAction: bindActionCreators({REQUEST_USER}, dispatch)
	}
}

export default connect(
mapStateToProps,
mapDispatchToProps
)(FeedbackManager)