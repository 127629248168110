import { createAction, createReducer } from 'redux-act';
import Inmutable from 'immutable';

const RESPONSE_LOGIN = createAction('RESPONSE_LOGIN');
const REQUEST_LOGIN = createAction('REQUEST_LOGIN');
const REQUEST_LOGIN_LINKEDIN = createAction("REQUEST_LOGIN_LINKEDIN");
const REQUEST_SIGNUP = createAction('REQUEST_SIGNUP')
const REQUEST_SIGNUP_LINKEDIN = createAction("REQUEST_SIGNUP_LINKEDIN");
const REQUEST_USER =createAction("REQUEST_USER");
const RESPONSE_USER = createAction("RESPONSE_USER")
const initialState = Inmutable.fromJS({
    authenticated: false,
    loginError: "",
    profile:{
      image_url:null,
      first_name: null,
      last_name: null
    }
})

export default createReducer({
  [RESPONSE_LOGIN]: (state,payload) => 
    state.set("authenticated",payload),
  [RESPONSE_USER]: (state,payload) => 
    state.set("profile",payload)
},initialState);



export {RESPONSE_LOGIN,REQUEST_LOGIN, 
        REQUEST_LOGIN_LINKEDIN, REQUEST_SIGNUP,
        REQUEST_SIGNUP_LINKEDIN, REQUEST_USER, RESPONSE_USER};
