import { delay } from 'redux-saga'
import { put, takeLatest, fork, select } from 'redux-saga/effects'
import Immutable from 'immutable'
import { getInterviews, getInterviewsResponse,
  getQuestions, getQuestionsResponse,
  saveAnswers, saveAnswersResponse,
  getAnswers,
  getFeedback, getFeedbackResponse,
  getScoreResponse,
  uploadVideo,
  setAnswers,
  setInterview,
  PERSONALITY_RESPONSE
} from '../reducers/interview';
import interviewAPI from '../api/interview'
import vacancyAPI from '../api/vacancy'

function randomBetween(start, end){
  let index = Math.floor(Math.random() * (end - start)); 
  return index + start;
}

export function* requestGetQuestions(data) {
  const {callback} = data.payload;
  try{
    const response = yield vacancyAPI.requestVacancy()
    console.log(response.body)
		if(response.body){
      const vacancy = response.body;
      yield put(setInterview({...vacancy, uuid: vacancy.self_interview.uuid}));
      const interviewId = vacancy.uuid;
      let competencies = vacancy.competencies;
      const competenciesKeys = Object.keys(vacancy.competencies);
      competencies = competenciesKeys.filter((c)=>competencies[c].enabled === 'true')
      const keyQuestions = Immutable.fromJS(vacancy.key_questions);
      // Get questions
      const questions = yield interviewAPI.doGetQuestions(interviewId, competencies)     
      
      // Set questions to state
      console.log("Questions", competencies, questions);
      yield put(getQuestionsResponse(questions));			
      
      // Check if key question exists
      const keyQuestionText = keyQuestions ? keyQuestions.getIn(["questions", 0, "text"]) :null;    
      
      // Get answers and set to state
      const data = yield requestGetAnswers({interviewId, hasKeyQuestion: !!keyQuestionText})
      
      
      if(keyQuestionText && data != null){
        // Check if key question exist in answers
        const currentCompetency =  data.currentCompetency + 1;
        // If key question doesn't exist in answers add it at random index
        let insertIndex = data.keyQuestionIndex || randomBetween(currentCompetency, questions.length)          
        console.log("Insert index ", data.keyQuestionIndex, currentCompetency, insertIndex, keyQuestionText)
        // Create dummy key question as in firebase
        let keyQuestion= {
          name: "Key Question",
          questionArray:[{
            questionId: "KQ-0",
            competencyId: "KQ",
            answers: "video",
            questions: [
              keyQuestionText
            ],
            type: "video"
          }]						
        }
        // Add it at existing or random index
        questions.splice(insertIndex, 0, keyQuestion);				
        console.log("Question after search ", questions)
        yield put(getQuestionsResponse(questions))      
        
      }else{
        console.log("Doesn't contain key question")
      }   
      callback(data)
    }
  }catch(e){
    console.log("Error getting questions",e)
    callback(null)
  }
}

export function* wathRequestGetQuestions() {
  yield takeLatest( getQuestions.getType() , requestGetQuestions);
}



export function* requestSaveAnswers(data) {
  console.log("Saving answers");
  const {interviewId} = data.payload;
  try{
    let answers = yield select((state)=> state.interview.get("answers").toJS()); 
    const response = yield interviewAPI.doSaveAnswers(interviewId, answers)
    yield put(saveAnswersResponse(response.body));
  }catch(e){
    console.log("Error saving answers ",e);
  }
}

export function* requestGetAnswers(data ) {
  console.log("Getting answers from API");
  const {interviewId, hasKeyQuestion} = data;
  try{
    let questions = yield select((state)=> state.interview.get("questions"));
    if(!questions) {      
      return null;
    }
    const response = yield interviewAPI.doGetAnswers(interviewId)
    if(response.body.answers){
      yield put(setAnswers(response.body));
      let answers = response.body.answers;
      let keys = Object.keys(answers);
      let currentCompetency = -1;
      let keyQuestionIndex = null;
      let currentQuestion = 0;
      let progress = 0;
      let levelmin = 0;
      let levelmax = 3; 
      let lastAnswer = 0;
      let total = questions.reduce((acc,curr) => {
        console.log(acc,curr)
        return acc + curr.get("questionArray").size;  
      },0);
      if(hasKeyQuestion){
        total = total+1;
      }
      keys.forEach((key, index)=>{
        if(key == "KQ") {
          keyQuestionIndex = index
          progress++;
          currentQuestion=0;
          return;
        }
        currentCompetency = keys.length-1;
        let answerKeys = Object.keys(answers[key])
        answerKeys = answerKeys.filter((a)=>answers[key][a].text==undefined)
        let answerKey = answerKeys[answerKeys.length-1];
        levelmin = answers[key][answerKeys[0]].levelmin
        levelmax = answers[key][answerKeys[0]].levelmax
        let questionIndex = questions.findIndex((question)=> question.get("competencyId") == key)
        let questionArray = questions.getIn([questionIndex,"questionArray"]);
        console.log("Last answer key",progress, answerKey, questionArray)
        currentQuestion = questionArray.findIndex((question)=>{
          progress++;
          return question.get("questionId")== answerKey;
        })
        if(currentQuestion==questionArray.size-1
          && currentQuestion!=-1){
          currentQuestion=0;
          currentCompetency++;
        }
      })
      console.log("Progress",progress, total)
      progress = (progress) / total;
      console.log("Progress",progress)
      console.log("Competency ",currentCompetency, "Question", currentQuestion)
      currentQuestion++;
      return {
        currentCompetency,
        keyQuestionIndex,
        currentQuestion,
        levelmin,
        levelmax,
        progress,
        ready: true
      };
    }else{
      return null;
    }
  }catch(e){
    console.log("Error getting answers ",e);
    return null;    
  }
}

export function* wathRequestSaveAnswers() {
  yield takeLatest( saveAnswers.getType() , requestSaveAnswers);
}
/*export function* wathGetSaveAnswers() {
  yield takeLatest( getAnswers.getType() , requestGetAnswers);
}*/

export function* requestGetFeedback(data) {
  const response = yield vacancyAPI.requestVacancy()
  console.log(response.body)
	if(!response.body){
    return
  }
  const vacancy = response.body;
  yield put(setInterview({...vacancy, uuid: vacancy.self_interview.uuid}));
  const interviewId = vacancy.self_interview.uuid
  try{
    const response = yield interviewAPI.doGetEvaluation(interviewId)
    
    if (!response.body || !response.body.assessment || !response.body.assessment.competencies){
      return;
    }
    const evaluation = response.body.assessment.competencies
    const score = response.body.score
    console.log("Score -> "+score)
    yield put(getScoreResponse(score));
    let evaluation_keys = Object.keys(evaluation)
    let feedback = yield Promise.all(evaluation_keys.map( (key) => {
      return interviewAPI.doGetFeedbackText(key, evaluation[key].percentile)
    }))
    console.log(feedback)
    yield put(getFeedbackResponse(feedback));
    yield put(PERSONALITY_RESPONSE(response.body.assessment.personality_text));
    
  }catch(e){
    console.log(e)
  }
}
export function* wathRequestGetFeedback() {
  yield takeLatest( getFeedback.getType() , requestGetFeedback);
}

export function* requestUploadVideo(data) {
  const {interviewId, videoId, video, type, callback, onUploading, onProgress} = data.payload;
  try{
    const {promise, request} = yield interviewAPI.doUploadVideo(interviewId, videoId, video, onProgress, type)
    onUploading(request)
    yield promise;
    callback(true);
  }catch(e){
    console.log("Cannot upload video",e);
    callback(false);
  }
}
export function* wathRequestUploadVideo() {
  yield takeLatest( uploadVideo.getType() , requestUploadVideo);
}