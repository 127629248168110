import React, { Component } from 'react';
import closeImage from '../../images/closeWhite.png';

export default class FeedbackDetail extends Component {
	constructor(props){
		super(props);
		this.onClose = this.onClose.bind(this)

	}

	render() {
		const{image,text,description, name} = this.props;
		return (
			<div style={{flex:1,backgroundColor:"#FFFFFF", margin:"0 auto", width:360,display: "flex", flexDirection: "column",alignItems: "center"}}>
				<div style={{height:300,width:"100%",    backgroundSize: "contain", backgroundRepeat: "no-repeat",    backgroundPosition: "center", backgroundImage:"url(/images/header.png)",alignItems:"center",display:"flex", flexDirection:"column"}} >
					<div style={{position: "absolute",left:10, top:10, width:26,height:26,objectFit: "contain"}} onPress={this.onClose} >
						<img src={closeImage} style={{width:26,height:26,objectFit: "contain"}} resizeMode="contain"/>
					</div>
					<span style={{width:null, color:"white",marginBottom:45,  fontSize:18,paddingTop:7,fontWeight: "600",width:200,textAlign:"center"}}>
						Herman feedback report
					</span>
					<img src={image} style={{height:160,width:160}}/>
				</div>
				<span style={{marginTop:10,color:"#1578FF",fontSize:15,textAlign:"center"}}>
					{text}
				</span>
				<div style={{marginTop:10}}>

					<span style={{display:"flex", fontSize:15,textAlign:"center",marginLeft:20,marginRight:20}}>
						{description.replace("%name%",name)}
					</span>
				</div>
			</div>
		)
	}
	onClose() {		
		this.props.goTo('home');
	}

}