
import { all } from 'redux-saga/effects'
import {watchRequestLogin,watchRequestSignup, watchRequestLoginLinkedin, watchRequestSignupLinkedin, watchRequestUser} from './session.js'
import {watchRequestPositions,watchRequestNewVacancy, 
    watchRequestConfig, watchRequestVacancy, watchRequestUpdateVacancy,
    watchRequestUpdateVideo, watchRequestUpdateLogo, watchRequestNewCandidates,
    watchRequestFeedbackText} from './vacancy.js'
import {watchRequestProfile} from './profile.js'
import {wathRequestGetFeedback, wathRequestGetQuestions,
    wathRequestSaveAnswers, 
    wathRequestUploadVideo} from './interview'
export default function* rootSaga () {
    yield all([
        watchRequestLogin(),watchRequestSignup(), watchRequestLoginLinkedin(), watchRequestSignupLinkedin(),
        watchRequestPositions(),watchRequestNewVacancy(), 
        watchRequestConfig(), watchRequestVacancy(), watchRequestUpdateVacancy(),
        watchRequestUpdateVideo(), watchRequestUpdateLogo(), watchRequestNewCandidates(),
        watchRequestFeedbackText(),
        watchRequestProfile(),
        wathRequestGetQuestions(),
        wathRequestSaveAnswers(),
        wathRequestUploadVideo(),
        wathRequestGetFeedback(),
        watchRequestUser()

    ])
}    
