/* global IN*/

import React, { Component } from	 'react';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';
import {ToastContainer, toast } from 'react-toastify';
import logoColor from '../../images/logoColor.svg';
import lineGrayWhite from '../../images/lineGrayWhite.svg';

import './index.css';
import HButton from '../../components/hbutton';
import HInput from '../../components/hinput';
import querystring from 'query-string';
import { v4 } from 'uuid'
import {REQUEST_LOGIN, REQUEST_LOGIN_LINKEDIN, REQUEST_SIGNUP, REQUEST_SIGNUP_LINKEDIN} from '../../reducers/session.js';

const AUTHORIZATION_URL = 'https://www.linkedin.com/oauth/v2/authorization'




class Login extends Component {
	constructor(props) {
		super(props);
		this._showRegister = this._showRegister.bind(this);
		this._showSignin = this._showSignin.bind(this);
		this._showSigninLinkedin = this._showSigninLinkedin.bind(this);
		//this.requestSignupLinkedin = this.requestSignupLinkedin.bind(this);
		//this.requestSigninLinkedin = this.requestSigninLinkedin.bind(this);
		this._showPassowrd = this._showPassowrd.bind(this);
		this.doLogin = this.doLogin.bind(this);
		this.doSignup = this.doSignup.bind(this);
		this.onChangeText = this.onChangeText.bind(this);
		this._authorized = this._authorized.bind(this);
		this.state={
			type:"signin",
			username:"",
			password:"",
			showingPassword:false
		}
	}
	componentDidMount(){
		const parsed = querystring.parse(window.location.search);
		if(parsed.code){
			this.props.actions.REQUEST_LOGIN_LINKEDIN({				
				token: parsed.code,
				onComplete:(response)=>{
					if(!response.success){
						toast(response.message);
					}
				}
			})
		}
	}
	render() {
		const {type,username,password, showingPassword} = this.state;
		return(
			<div className="loginBackground">
				<div className="container-fluid full-height">
					<div className="row justify-content-center full-height-40">
						<div className="col-md-6 window login">
							<div className="row justify-content-center margin-top-20">
								<img src={logoColor}/>
							</div>
							<div className="row justify-content-center blue font-weight-bold margin-top-20">
								Hi, I´m Herman
							</div>
							<div className="row justify-content-center font-weight-light margin-top-10">
								Make the right hiring decisions
							</div>
							{type =="signin"?(
								<div>
									<div className="row justify-content-center font-weight-light margin-top-10">
										<div className="col-11 col-sm-8">
											<HButton type="linked" onClick={this._showSigninLinkedin}>
												<i className="fa fa-linkedin-square linked icon" aria-hidden="true"></i>
					      				<span className="font-weight-light">Sign in with</span> <span className="font-weight-semi-bold">Linked in</span>
					      			</HButton>
				      			</div>
									</div>
									<div className="row justify-content-center margin-top-20">
				      			<div className="col-md-8 col-11">
				      				<img className="d-inline-block w-40 h-1" src={lineGrayWhite}/>
					      			<div className="d-inline-block w-20 text-center font-weight-light"> 
					      				or
					      			</div>
					      			<img className="d-inline-block w-40 h-1" src={lineGrayWhite}/>
				      			</div>
				      		</div>
				      		<div className="row justify-content-center margin-top-20">
				      			<div className="col-md-7 col-9">
				      				<HInput value={username} onChange={this.onChangeText} stateId="username" placeholder="User" type="email" name="email" />
				      			</div>
				      		</div>
				      		<div className="row justify-content-center margin-top-20">
				      			<div className="col-md-7 col-9">
				      				<HInput value={password} onChange={this.onChangeText} stateId="password" placeholder="Password" type={!showingPassword? "password":"text"} name="password" />
				      			</div>
				      		</div>
				      		<div className="row justify-content-center margin-top-20 text-center font-weight-light clickable" onClick={this._showPassowrd}>
				      			Show Password
				      		</div>
				      		<div className="row justify-content-center margin-top-20 text-center ">
				      			<div className="col-md-7 col-9">
				      				<HButton type="primary" onClick={this.doLogin}>Log in</HButton>
				      			</div>
				      		</div>
				      		<div className="row justify-content-center margin-top-20 text-center font-weight-light font-smaller">
				      			Forgot password
				      		</div>
				      		<div className="row justify-content-center margin-top-20 text-center footer font-small ">
				      			<div>
				      				<span className="font-weight-light">You don't have an account?</span> <span onClick={this._showRegister} className="blue font-weight-bold margin-left-10 clickable">Register</span>
				      			</div>
				      		</div>
			      		</div>
			      	):(
			      		<div>
									<div className="row justify-content-center font-weight-light margin-top-10">
										<div className="col-11 col-sm-8">
											<HButton type="linked" onClick={this._showSigninLinkedin}>
												<i className="fa fa-linkedin-square linked icon" aria-hidden="true"></i>
					      				<span className="font-weight-light">Sign up with</span> <span className="font-weight-semi-bold">Linked in</span>
					      			</HButton>
				      			</div>
									</div>
									<div className="row justify-content-center margin-top-20">
				      			<div className="col-md-8 col-11">
				      				<img className="d-inline-block w-40 h-1" src={lineGrayWhite}/>
					      			<div className="d-inline-block w-20 text-center font-weight-light"> 
					      				or
					      			</div>
					      			<img className="d-inline-block w-40 h-1" src={lineGrayWhite}/>
				      			</div>
				      		</div>
			      			<div className="row justify-content-center margin-top-20">
				      			<div className="col-md-7 col-9">
				      				<HInput placeholder="First name" type="text" name="first_name"  onChange={this.onChangeText} stateId="first_name"/>
				      			</div>
				      		</div>
									<div className="row justify-content-center margin-top-20">
				      			<div className="col-md-7 col-9">
				      				<HInput placeholder="Last name" type="text" name="last_name"  onChange={this.onChangeText} stateId="last_name"/>
				      			</div>
				      		</div>
				      		<div className="row justify-content-center margin-top-20">
				      			<div className="col-md-7 col-9">
				      				<HInput placeholder="E-mail" type="text" name="email"  onChange={this.onChangeText} stateId="email"/>
				      			</div>
				      		</div>
				      		<div className="row justify-content-center margin-top-20">
				      			<div className="col-md-7 col-9">
				      				<HInput placeholder="Your industry" type="text" name="industry"  onChange={this.onChangeText} stateId="industry"/>
				      			</div>
				      		</div>
				      		<div className="row justify-content-center margin-top-20">
				      			<div className="col-md-7 col-9">
				      				<HInput placeholder="Your current role" type="text" name="role"  onChange={this.onChangeText} stateId="role"/>
				      			</div>
				      		</div>
				      		<div className="row justify-content-center margin-top-20">
				      			<div className="col-md-7 col-9">
				      				<HInput placeholder="Company name" type="text" name="company"  onChange={this.onChangeText} stateId="company"/>
				      			</div>
				      		</div>
				      		<div className="row justify-content-center margin-top-20">
				      			<div className="col-md-7 col-9">
				      				<HInput placeholder="Password" type={!showingPassword? "password":"text"} name="password"  onChange={this.onChangeText} stateId="password"/>
				      			</div>
				      		</div>
				      		<div className="row justify-content-center margin-top-20 text-center font-weight-light clickable" onClick={this._showPassowrd}>
				      			Show Password
				      		</div>
				      		<div className="row justify-content-center margin-top-20 text-center font-weight-light font-small ">
				      			By clicking Register, I accept the Terms of Service and the Privacy Policy.
				      		</div>
				      		<div className="row justify-content-center margin-top-20 text-center ">
				      			<div className="col-md-7 col-9">
				      				<HButton type="primary" onClick={this.doSignup}>Register</HButton>
				      			</div>
				      		</div>
				      		<div className="row justify-content-center margin-top-20 text-center footer font-small ">
				      			<div>
				      				<span className="font-weight-light">Do you have an account?</span> <span onClick={this._showSignin} className="blue font-weight-bold margin-left-10 clickable">Sign in</span>
				      			</div>
				      		</div>
				      	</div>
				      )}
		      		
						</div>
					</div>
				</div>
				<ToastContainer 
						position="top-right"
						autoClose={5000}
						hideProgressBar={false}
						newestOnTop={false}
						toastClassName="dark-toast"
						progressClassName='transparent-progress'						
						closeOnClick
						pauseOnHover
					/>
			</div>
		);
	}

	onChangeText(value,stateId) {
		const newState={
		}
		newState[stateId] = value;
		this.setState(newState);
	}
	
	_showRegister() {
		this.setState({
			type:"register"
		});
	}
	_showSignin() {
		this.setState({
			type:"signin"
		});
	}
	_showSigninLinkedin() {
		/*if(IN.User.isAuthorized()){
			return this.requestSigninLinkedin();
		}
		IN.Event.onOnce(IN, "auth", this.requestSigninLinkedin);
		IN.User.authorize(this._authorized);*/
		const url = `${AUTHORIZATION_URL}?${querystring.stringify({
			response_type: 'code',
			client_id: '78xy6pjxexae37',
			scope: 'r_liteprofile r_emailaddress',
			state: v4(),
			redirect_uri: 'https://profile.hermanpro.com/verify',
		})}`
		window.open(url ,"_self")
	}
	_authorized(){
		console.log("Authorized")
	}
	/*requestSigninLinkedin() {
		IN.API.Profile("me").fields("email-address").result( (data) =>{
			this.props.actions.REQUEST_LOGIN_LINKEDIN({
				email: data.values[0].emailAddress,
				token: IN.ENV.auth.oauth_token,
				onComplete:(response)=>{
					if(!response.success){
						toast(response.message);
					}
				}
			})
		}).error(()=>{
			toast("LinkedIn fails to authenticate the user.");
		});
	}*/
	/*requestSignupLinkedin() {
		IN.API.Profile("me").fields("email-address").result( (data) =>{
			this.props.actions.REQUEST_SIGNUP_LINKEDIN({
				email: data.values[0].emailAddress,
				token: IN.ENV.auth.oauth_token,
				onComplete:(response)=>{
					if(!response.success){
						IN.User.logout();
						toast(response.message);
					}
				}
			})
		}).error(()=>{
			IN.User.logout();
			toast("LinkedIn fails to authenticate the user.");
		});
	}*/
	doLogin() {
		this.props.actions.REQUEST_LOGIN({
			username: this.state.username,
			password: this.state.password,
			onComplete:(response)=>{
				if(!response.success){
					toast(response.message);
				}
			}
		});
	}
	_showPassowrd() {
		this.setState({
			showingPassword: !this.state.showingPassword
		})
	}
	doSignup(){
		this.props.actions.REQUEST_SIGNUP({
			data: {
				first_name: this.state.first_name,
				last_name: this.state.last_name,
				password: this.state.password,
				email: [this.state.email],
				company: this.state.company,
				role: this.state.role,
				industry : this.state.industry
			},
			onComplete:(response)=>{
				if(!response.success){
					toast(response.message);
				}
			}
		})
	}
}

function mapStateToProps(state) {
  return {
    
  }
}
function mapDispatchToProps(dispatch) {
    return { actions: bindActionCreators({ REQUEST_LOGIN, REQUEST_LOGIN_LINKEDIN, REQUEST_SIGNUP, REQUEST_SIGNUP_LINKEDIN }, dispatch) }
}

export default connect(mapStateToProps,mapDispatchToProps)(Login)