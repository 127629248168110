import React, { Component } from 'react';


class Loader extends Component {


    render(){
        const { loading,
            onCancel,
            progress} = this.props;
        return ( loading?( 
            <div className="background-modal">
                <div className="container newVacancy">
                    <div className="" role="document" style={{textAlign: "center"}}>
                        <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
                    </div>
                </div>
            </div>
            ):null
        )
    }
}
export default Loader;