import React from 'react';
import ReactDOM from 'react-dom';
import firebase from 'firebase';
import './index.css';
import 'react-toastify/dist/ReactToastify.min.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './index.css';
import './fonts/index.css';
var config = {
	apiKey: "AIzaSyAOhami7S9y9LRDJIbR0nCxlgsh70K9n1k",
	authDomain: "herman-prod.firebaseapp.com",
	databaseURL: "https://herman-prod.firebaseio.com",
	projectId: "herman-prod",
	storageBucket: "herman-prod.appspot.com",
	messagingSenderId: "886736181187",
	appId: "1:886736181187:web:ee9cf8dd5eafba2c"
};
firebase.initializeApp(config);
window.CLIENT_CREDENTIALS= "VEtUM2hONG4xWFpHQ1hLVVlNQTZZTEs0cFgzdmJ4NEFmSGVKMjNuRDphWkpnSjdnbzdDVTZkUm5CWGtUS2hSckJSQUJoS21IOERRZWN3S1Z0UE9UM3B3ZjdlWEpkTzNOcTA3OVVKR1ppRWlqdkJ1b1FmUkc0VWVtWTFXT3FaWjlUbjlTSXBUSlVvU1B6ZTBwTFo0M0NucXJjR2lsSTU2b0U2a0NWUmhzNw==";
window.API_BASE="https://api.meet-herman.com";

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
