import request from 'superagent';

export default {
	/*requestVacancies:(company) =>
		request.get(window.API_BASE+"/api/assessment/vacancy/all/")
						.set('Authorization', 'Bearer '+localStorage.getItem('accessToken'))
	,*/
	requestPositions:()=>
		request.get(window.API_BASE+"/api/assessment/position/all/")
						.set('Authorization', 'Bearer '+localStorage.getItem('accessToken'))
	,
	requestNewVacancy:(vacancy) =>
		request.post(window.API_BASE +"/api/assessment/vacancy/self/")
			.set('Authorization', 'Bearer '+localStorage.getItem('accessToken'))
			.send(vacancy)
	,
	requestUpdateVacancy:(vacancy,id) =>
	request.patch(window.API_BASE +"/api/assessment/vacancy/self/")
		.set('Authorization', 'Bearer '+localStorage.getItem('accessToken'))
		.send(vacancy)
	,
	requestConfig:(config) =>
		request.post(window.API_BASE +"/api/assessment/competency/config/")
			.set('Authorization', 'Bearer '+localStorage.getItem('accessToken'))
			.send(config)
	,
	requestVacancy:(id) =>
		request.get(window.API_BASE +"/api/assessment/vacancy/self/")
			.set('Authorization', 'Bearer '+localStorage.getItem('accessToken'))
	,
	requestUpdateVideo:(id,video)=>
	request.post(window.API_BASE +"/api/assessment/vacancy/"+id+"/video/")
		.set('Authorization', 'Bearer '+localStorage.getItem('accessToken'))
		.attach("video",video,"video.webm")
	,
	requestUpdateLogo:(id, image)=>
		request.post(window.API_BASE +"/api/assessment/vacancy/"+id+"/logo/")
			.set('Authorization', 'Bearer '+localStorage.getItem('accessToken'))
			.attach("image",image)
	,
	requestNewCandidates:(id, candidates)=>
		request.post(window.API_BASE +"/api/assessment/vacancy/"+id+"/interview/")
			.set('Authorization', 'Bearer '+localStorage.getItem('accessToken'))
			.send(candidates),
	requestFeedbackText:()=>
			request.get("https://herman-prod.firebaseio.com/feedback.json")				
}