import React, { Component } from 'react';
import HButton from '../../components/hbutton';
import Header from './header';
import CheckWhite from '../../images/checkWhite.png';
import lineBlue from '../../images/lineBlue.png'
import {ToastContainer, toast } from 'react-toastify';
import {Style} from './style'
export default class QuestionText extends Component{
	constructor(props) {
		super(props);
		this._next = this._next.bind(this);
		this.onChange = this.onChange.bind(this);
		this.state = {
			answer: ''
		}
	}
	render() {
		const{question,progress,lastAnswer}= this.props;
		const{answer} = this.state;		
		return (
			<div style={{display:"flex", flexDirection:"column",backgroundColor:"white"}}>
				<ToastContainer 
							position="top-right"
							autoClose={5000}
							hideProgressBar={false}
							newestOnTop={false}
							toastClassName="dark-toast"
							progressClassName='transparent-progress'						
							closeOnClick
							pauseOnHover
						/>
				<Header progress={progress} back={this.props.back}>
					
				</Header>
				<div>

				</div>
				<div style={{marginTop:20,alignItems:"center",marginLeft:20,marginRight:20}}>
					<span style={{...Style.blackText,textAlign:"center",fontSize:18,fontWeight:"500"}}>{question ? question.get('description'): ''}</span>
				</div>
				<div>
					<div style={{marginTop:20,alignItems:"center"}}>
						<span style={{...Style.center,...Style.blackText,fontSize:16,marginLeft:10,marginRight:10}}>
							{question ? question.getIn(['questions',lastAnswer]): ''}
						</span>
					</div>
					<div style={{ marginTop:10,alignItems:"center", display:"flex", flexDirection:"column"}}>
						<img src={lineBlue} style={{minWidth:230,marginTop:10,marginBottom:10, objectFit: "cover",width:"90%", height:1}} />
							<span style={{...Style.blackText,textAlign:"center",fontSize:18,fontWeight:"400"}}>
								Your answer
							</span>
						<textarea style={{minWidth:230,backgroundColor:"#ebebeb",marginTop:20,fontSize:16,color:"black",textAlign:"center",alignItems:"flex-start", width:"90%", border:"none"}} 
									onChange={this.onChange} 
									multiline={true} 
									value={answer} 
									rows={10}

									placeholder="Describe your action plan" >
						</textarea>
					</div>
				</div>
				<div style={{...Style.horizontalCenterAlign,position:"absolute",bottom:10, width: "100%"}}>
					<div style={{width:320}}>
						<HButton type="transparent" onClick={this._next}>
							Save and submit
						</HButton>
					</div>
				</div>
			</div>
		)
	}
	
	onChange(ev) {
		this.setState({
			answer:ev.target.value
		});
	}
	countWords(str) {
		return str.trim().split(/\s+/).length;
	}
	_next() {
		let {question} = this.props;
		const {answer} = this.state;
		if(this.countWords(answer)<50){
			return toast("The video should contain 50 words at least", 1000);
		}		
		console.log("Text Answer -> ",answer);
    	this.props.setAnswer({
			answer: {
				text: answer,
				competencyId: question.get("competencyId"),
				questionId: question.get("questionId")
			}
    	});
    	this.props.next();
	}

}