import React from 'react';
import { Route } from 'react-router';
import Login from '../screens/login';
import Start from '../screens/start';
import Home from '../screens/home';
import Interview from '../screens/interview/interviewManager';
import Feedback from '../screens/feedback/feedbackManager';


const routes = (
	<div>
	  <Route exact path="/" component={Start}/>
	  <Route path="/verify" component={Login}/>
	  <Route path="/login" component={Login}/>	  	  
	  <Route path="/new" component={Home}/>
	  <Route path="/segment" component={Home}/>
	  <Route path="/config" component={Home}/>
	  <Route path="/important" component={Home}/>
	  <Route path="/competencies" component={Home}/>
	  <Route path="/welcome" component={Home}/>
	  <Route path="/interview" component={Interview}/>
	  <Route path="/feedbackManager" component={Feedback}/>

  </div>
);


export {routes}