import React, { Component } from 'react';
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'react-router-redux';
import NewVacancy from '../newVacancy';
import './index.css';
import check from '../../images/check.svg'
import { REQUEST_VACANCY } from '../../reducers/vacancy'
import { REQUEST_PROFILE } from '../../reducers/profile'

class WizardVacancy extends Component {

  constructor(props){
    super(props);    
  }
  componentDidMount() {
    this.props.vacancyActions.REQUEST_VACANCY()
  }

  render() {
    return (
      <div>        
        <div className="background-modal">
          {this.renderSection()}
        </div>
      </div>
    )
  }
  getNavClass(required) {
    const {section} = this.state;
    return required > section ? "inactive": "";
  }
  goTo(path) {
    if(this.props.uuid){
      this.props.actions.push(`${path}/${this.props.uuid}`);
    }else{
      this.props.actions.push(path);
    }
  }
  renderSection() {
    return (<NewVacancy screen={this.props.screen} push={this.goTo.bind(this)}/>)          
  }
}
function mapStateToProps(state) {
  return {
    
  }
}
function mapDispatchToProps(dispatch) {
    return { actions: bindActionCreators({ push }, dispatch),
             vacancyActions: bindActionCreators({REQUEST_VACANCY, REQUEST_PROFILE}, dispatch) }
}

export default connect(mapStateToProps,mapDispatchToProps)(WizardVacancy)