import { put, takeLatest, select } from 'redux-saga/effects'
import Immutable from 'immutable'
import { RESPONSE_VACANCIES,REQUEST_VACANCIES,
					RESPONSE_POSITIONS,REQUEST_POSITIONS,
					RESPONSE_CONFIG, REQUEST_CONFIG,
					RESPONSE_VACANCY, REQUEST_VACANCY,
					REQUEST_NEW_VACANCY, REQUEST_UPDATE_VACANCY,
					REQUEST_UPDATE_VIDEO, 
					REQUEST_UPDATE_LOGO,
					REQUEST_FEEDBACK_TEXT,
					RESPONSE_FEEDBACK_TEXT,
					REQUEST_SHARE_URL,
					REQUEST_NEW_CANDIDATES} from '../reducers/vacancy'
import ApiVacancy from '../api/vacancy'
import { EWOULDBLOCK } from 'constants';
import {REQUEST_PROFILE, READ_CONFIG, REQUEST_INITIALIZE} from '../reducers/profile'


/*
export function* requestVacancies() {
	const response = yield ApiVacancy.requestVacancies();
	try{
		if(Array.isArray(response.body)){
			yield put(RESPONSE_VACANCIES(
				Immutable.fromJS(response.body)
			))
		}
	}catch(e){
		
	}
}*/

export const getInitialized = (state) => state.profile.get("initialized")


export function* requestVacancy(data) {

	try{
		const initialized = yield select(getInitialized)
		console.log("Initializing?", initialized)
		if(!initialized){
			console.log("Getting vacancy from API")
			const response = yield ApiVacancy.requestVacancy();
			console.log(response.body)
			if(response.body){
				yield put(READ_CONFIG(response.body))
				yield put(REQUEST_CONFIG({
					ceo_level: response.body.config.ceo_level,
					scope: response.body.config.scope,
					area: response.body.config.area
				}))
				yield put(REQUEST_INITIALIZE())
			}else{
				yield put(REQUEST_PROFILE())
			}
		}
	}catch(e){
		console.log(e)
		yield put(REQUEST_PROFILE())
	}
}

export function* requestPositions() {
	const response = yield ApiVacancy.requestPositions();
	try{
		if(Array.isArray(response.body)){
			yield put(RESPONSE_POSITIONS(
				Immutable.fromJS(response.body)
			))
		}
	}catch(e){
		
	}
}	

export function* requestConfig(data) {
	const response = yield ApiVacancy.requestConfig(data.payload);
	try{
		yield put(RESPONSE_CONFIG(
			Immutable.fromJS(response.body)
		))
	}catch(e){
		
	}
}	

export function* requestNewUpdateVacancy(data) {
	try{
		let response=null;
		if(data.payload.uuid) {
			response = yield ApiVacancy.requestUpdateVacancy(data.payload.vacancy, data.payload.uuid);
		}else{
			response = yield ApiVacancy.requestNewVacancy(data.payload.vacancy);
		}
		data.payload.onComplete({success:response.status==201});
		if(response.status==201){
			console.log(response.body);
			yield put(REQUEST_VACANCY(
				response.body.uuid
			))
		}
	}catch(e){
		data.payload.onComplete({success:false});
	}
}

export function* requestUpdateVideo(data) {
	try{
		const response = yield ApiVacancy.requestUpdateVideo(data.payload.uuid, data.payload.video);
		data.payload.onComplete({success:response.status==201});
	}catch(e){
		data.payload.onComplete({success:false});
	}
}

export function* requestUpdateLogo(data){
	try{
		const response = yield ApiVacancy.requestUpdateLogo(data.payload.uuid, data.payload.image);
		data.payload.onComplete({success:response.status==201});
	}catch(e){
		data.payload.onComplete({success:false});
	}
}

export function* requestNewCandidates(data) {
	try{
		const response = yield ApiVacancy.requestNewCandidates(data.payload.uuid, data.payload.candidates);
		data.payload.onComplete({success:response.status==201});
	}catch(e){
		data.payload.onComplete({success:false});
	}
}
export function* requestFeedbackText(data) {
	try{
		const response = yield ApiVacancy.requestFeedbackText();
		yield put(RESPONSE_FEEDBACK_TEXT(
			response.body
		))		
	}catch(e){

	}
}

/*export function* watchRequestVacancies() {
  yield takeLatest( REQUEST_VACANCIES.getType() , requestVacancies);
}*/

export function* watchRequestPositions() {
	yield takeLatest( REQUEST_POSITIONS.getType() , requestPositions);	
}

export function* watchRequestNewVacancy() {
	yield takeLatest( REQUEST_NEW_VACANCY.getType() , requestNewUpdateVacancy);	
}

export function* watchRequestUpdateVacancy() {
	yield takeLatest( REQUEST_UPDATE_VACANCY.getType() , requestNewUpdateVacancy);	
}

export function* watchRequestConfig() {
	yield takeLatest( REQUEST_CONFIG.getType() , requestConfig);	
}

export function* watchRequestVacancy() {
	yield takeLatest( REQUEST_VACANCY.getType() , requestVacancy );
}

export function* watchRequestUpdateVideo() {
	yield takeLatest( REQUEST_UPDATE_VIDEO.getType() , requestUpdateVideo );
}

export function* watchRequestUpdateLogo() {
	yield takeLatest( REQUEST_UPDATE_LOGO.getType(), requestUpdateLogo  );
}

export function * watchRequestNewCandidates() {
	yield takeLatest( REQUEST_NEW_CANDIDATES.getType(), requestNewCandidates);
}

export function * watchRequestFeedbackText() {
	yield takeLatest( REQUEST_FEEDBACK_TEXT.getType(), requestFeedbackText);	
}