import firebase from 'firebase';
import request from 'superagent';

const Store = {
    get:(term) => new Promise((resolve)=> resolve(localStorage.getItem(term)))
}

const api ={
	doGetAnswers(interviewId){
		return Store.get('accessToken')
		.then((accessToken)=>{
			return request.get(window.API_BASE+'/api/assessment/profile/interview/'+interviewId+"/answers/")
			.set('Authorization', `Bearer ${accessToken}`)
		})
	},
	doGetInterviews: (email,userType) => {
		return Store.get('accessToken')
		.then((accessToken)=>{
			return request.get(window.API_BASE+'/api/assessment/profile/interview/all/')
			.set('Authorization', `Bearer ${accessToken}`)
		})
	},
	doSaveAnswers(interviewId,answers){
		return Store.get('accessToken')
		.then((accessToken)=>{
			return request.post(window.API_BASE+'/api/assessment/profile/interview/'+interviewId+"/answers/")
			.type('json')
			.send({answers : answers})
			.set('Authorization', `Bearer ${accessToken}`)
		})
	},
	doGetQuestions:(interviewId,competencies, keyQuestion=null)=>{
		
		const questions=[];
		const getQuestions = (competencyId) =>{
			return firebase.database().ref(`competency/${competencyId}`).once("value")
				.then(function(snapshot){
					let q = snapshot.val();
					console.log(competencyId, q);
					try{
						questions.push(
								Object.assign({},q,{
									competencyId: competencyId,
									questionArray: 	Object.keys(q.question).map((qa)=> {
										if(q.question[qa].type == "simple") {
											let answerKeys = Object.keys(q.question[qa].answers);
											console.log(qa);
											let answerArray = answerKeys.map( (k)=> {
												return Object.assign({}, q.question[qa].answers[k],{key:k} );
											});
											return Object.assign({},{
												questionId:qa,
												competencyId:competencyId, 
												answerArray
											},q.question[qa])
										}else{
											return Object.assign({},{
												questionId:qa,
												competencyId:competencyId
											},q.question[qa])
										}
									})
								})
						);
					}catch(e){
						console.log(e);
					}
				});
		}
		const promises = competencies.map((competencyId) => getQuestions(competencyId))
		return Promise.all(promises)
			.then(()=> {					
				return questions
			})
	},
	doGetEvaluation: (interview) =>{
		return Store.get('accessToken')
		.then((accessToken)=>{
			return request.get(window.API_BASE+"/api/assessment/profile/interview/evaluation/"+interview)
			.set('Authorization', `Bearer ${accessToken}`)
			.type('json')
		})
	},
	doUploadVideo: (interviewId, videoId, video, onProgress, type)=>{
		return Store.get('accessToken')
		.then((accessToken)=>{
            return request.post( window.API_BASE+"/api/assessment/interview/"+interviewId+"/video/"+videoId+"/"+type +"/")
                .set('Authorization', `Bearer ${accessToken}`)
                .attach('video', video)
                .on('progress', event => {
                    onProgress(event.percent)
                })
		})
	},
	doGetFeedbackText: (competencyId, value)=>{
		return firebase.database().ref(`competency/${competencyId}`).once("value")
			.then((snapshot)=>{
				let competency = snapshot.val();
				let feedback = Object.values(competency.feedback)
				let lastFeedback = 0;
				feedback.forEach((f)=>{
					if (f.percentile <= value){
						lastFeedback = f;
					}
				})
				return {
					"id":competencyId,
					"title": competency.name,
					"percentile": value.percentile,
					"score": value.score,
					"description": lastFeedback ? lastFeedback.text: "Not available"
				}
			})
	},
}
export default api;