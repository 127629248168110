import React, { Component } from 'react';
import AO from '../../images/competencies/AO.png'
import CM from '../../images/competencies/CM.png'
import CO from '../../images/competencies/CO.png'
import DO from '../../images/competencies/DO.png'
import EI from '../../images/competencies/EI.png'
import ST from '../../images/competencies/ST.png'
import TC from '../../images/competencies/TC.png'
import TL from '../../images/competencies/TL.png'
import closeImage from '../../images/closeWhite.png';
const initials = require('initials');
const mapImages = {
	AO: AO,
	CM: CM,
	CO: CO,
	DO: DO,
	EI: EI,
	ST: ST,
	TC: TC,
	TL: TL
}
const PRIMARY_FONT ="sf_compact_display";


export default class Feedback extends Component {
	constructor(props){
		super(props);
		this.onClose = this.onClose.bind(this)
		this.sendEmail = this.sendEmail.bind(this);
	}

	render() {
		const {feedback, score, avatar, name, personalityText} = this.props;
		let percentile = score | 0;
		const abbr = initials(name);

		if (percentile > 100) {
			//percentile = 100
		}
		//percentile = percentile / 100;		
		return (
			<div style={{flex:1,backgroundColor:"#FFFFFF", margin:"0 auto", width:360,display: "flex", flexDirection: "column",alignItems: "center"}}>
				<div style={{height:300,width:"100%",    backgroundSize: "contain", backgroundRepeat: "no-repeat",    backgroundPosition: "center", backgroundImage:"url(/images/header.png)",alignItems:"center",display:"flex", flexDirection:"column"}} >
					<div style={{position: "absolute",left:10, top:10, width:26,height:26,objectFit: "contain"}} onPress={this.onClose} >
						<img src={closeImage} style={{width:26,height:26,objectFit: "contain"}} resizeMode="contain"/>
					</div>
					<span style={{color:"white",width:null, marginBottom:45,  fontSize:18,paddingTop:7,fontWeight: "600",width:200,textAlign:"center"}}>
						Herman feedback report
					</span>
					<div style={{width:97,height:97, borderRadius:97, overflow:"hidden"}}>
						{avatar?(
							<div>
								<img src={avatar} style={{width:97, height:97}}/>
							</div>
						):(
							<div style={{width:97, height:97, backgroundColor: "#ccc", color:"#fafafa"}}>
								{abbr | "Herman"}
							</div>
						)}						
					</div>					
					<span style={{color:"white",width:null, marginBottom:45, fontSize:18, paddingTop:7, fontWeight: "600", width:200, textAlign:"center"}}>
						{name}
					</span>
				</div>
				{feedback != null && feedback.count() !=0?(
					<span style={{marginTop:10,color:"#1578FF",fontWeight:'700', fontFamily:PRIMARY_FONT,fontSize:14,textAlign:"center"}}>
						Top evaluation competences
					</span>
				):null}
				<div style={{display:"flex",justifyContent:"center", flexWrap: 'wrap', flexDirection:"row",marginTop:10}}>
					{feedback.map((e,i)=>(
						<div key={i} onClick={()=>this._show(i)} style={{cursor:"pointer"}}>
							<img src={this.getImage(e.get("id"))} style={{width:54,height:54,marginRight:10, marginLeft:10, marginTop:10}} />
							
						</div>
					))}
					{feedback == null || feedback.count() ==0?(
						<div style={{display:"flex",justifyContent:"center", flexDirection:"column",marginTop:10}}>
							<div className="" role="document" style={{textAlign: "center"}}>
                        		<div className="lds-ring"><div></div><div></div><div></div><div></div></div>
                    		</div>
							<span style={{fontSize:20, color:"#1578FF", fontFamily:PRIMARY_FONT, textAlign:"center",marginTop:10}}>In review</span>
							<span style={{textAlign:"center", fontSize:12, fontFamily:PRIMARY_FONT, marginTop:10}}>Please come back in a couple of minutes.</span>
							<span style={{textAlign:"center", fontSize:12, fontFamily:PRIMARY_FONT, marginTop:10}}>If you have any problem please contact</span>
							<div onClick={()=>this.sendEmail()}>
								<span style={{textAlign:"center", color:"#1578FF", fontFamily:PRIMARY_FONT, fontSize:12, marginTop:10}}>support@meet-herman.com</span>
							</div>
						</div>
					):null}
					{personalityText ?(
						<span style={{display:"flex", marginTop:20, fontSize:15,textAlign:"center",marginLeft:20,marginRight:20}}>
							{personalityText}
						</span>
					):null}
				</div>
			</div>
		)
	}
	sendEmail() {
		window.open(`mailto:support@hermanpro.com`)		
	}
	onClose() {		
		this.props.goTo('home');
	}
	getImage(id) {
		let image =  mapImages[id] 

		if (!image) {
			return TL;
		}
		return image;
	}
	_show(id) {
		this.props.goTo(id);
	}

}