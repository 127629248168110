import React, { Component } from 'react';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

import './index.css';

import hermanWhite from '../../images/hermanWhite.svg';
import WizardVacancy from '../wizardVacancy';


class Home extends Component {
	constructor(props) {
		super(props);
		this._openDrawer = this._openDrawer.bind(this);
		this._addVacancy = this._addVacancy.bind(this);
		this._closeVacancy = this._closeVacancy.bind(this);
		this.renderNav = this.renderNav.bind(this);
		this.renderContent = this.renderContent.bind(this);
		this._goToHome = this._goToHome.bind(this);

		this.state={
			open:false,
			screen: props.location.pathname,
		};
	}
	componentWillReceiveProps(newProps){
		this.setState({
			screen: newProps.location.pathname
		});
	}

	render() {
		const{open,adding,preRecording,screen, payment} =this.state;
		return (
				<div className="home background-modal">
					<nav className="navbar navbar-expand-lg navbar-light bg-dark bg-blue space-bar">
						<form className="form-inline">
					   
					  </form>
					  <img src={hermanWhite}/>
					  <div className="dummy-title-button"/>
					</nav>
					{this.renderNav()}					
				  	{this.renderContent()}				  					
			  </div>
		)
	}
	renderNav(){
		const {screen} = this.state;
		return (
			<nav className="navbar navbar-expand-lg navbar-light bg-dark bg-gray center-bar">
				<div className="blue-text">
					Configure your assessment
				</div>
			</nav>
		)
	}
	renderContent(){
		let {screen} = this.state;
		let routeParts = screen.split("/");
		return (
			<WizardVacancy screen={routeParts[1]}  uuid={routeParts.length==3?  routeParts[2]: null}/>
		)
	}

	_addVacancy() {
		this.props.actions.push("/new");
	}
	_closeVacancy(success,needVideo){
		this.setState({
			adding:false
		})
		if(needVideo){
			this.props.actions.push("/record");
		}
	}

	_openDrawer() {
		this.setState({
			open: !this.state.open
		});
	}
	_goToHome() {
		this.props.actions.push("/home");
	}


}

function mapStateToProps(state) {
  return {
    
  }
}
function mapDispatchToProps(dispatch) {
    return { actions: bindActionCreators({ push }, dispatch) }
}

export default connect(mapStateToProps,mapDispatchToProps)(Home)