import React, { Component } from 'react';
import { getInterviews, getQuestions,setAnswer,saveAnswers,uploadVideo } from '../../reducers/interview';
import { push } from 'react-router-redux';
import Question from './question';
import QuestionVideo from './questionVideo';
import QuestionAudio from './questionAudio';
import QuestionText from './questionText';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Loader from './loader'

class InterviewManager extends Component{
	constructor(props){
		super(props);
		this._next = this._next.bind(this);
		this._setAnswer = this._setAnswer.bind(this);
		this._calibrate = this._calibrate.bind(this);
		this._setAnswerText = this._setAnswerText.bind(this);
		this._backQuestion = this._backQuestion.bind(this);

		this.uploadVideo = this.uploadVideo.bind(this);
		this.state = {
			currentCompetency:0,
			currentQuestion:0,
			progress: 0,
			levelmin: 0,
			levelmax: 3,
			ready: false
		};
	}
	componentDidMount() {
		//let competencies = this.props.selectedInterview.getIn(["vacancy","competencies"])
		//let keyQuestions = this.props.selectedInterview.getIn(["vacancy","key_questions"])
		//let competenciesEnabled = competencies.filter((v) => v.get('enabled') =='true' )
		/*this.props.interviewAction.getQuestions({
			interviewId:this.props.selectedInterview.get("uuid"),
			competency: competenciesEnabled.keySeq().toArray(),
			keyQuestions,
			callback:(currentState)=>{
				if(currentState){
					this.setState(currentState)
				}else{
					this.setState({ready:true})

				}
			}
		});*/
		this.props.interviewAction.getQuestions({
			callback:(currentState)=>{
				console.log("Callback getQuestions")
				if(currentState){
					this.setState(currentState)
				}else{
					this.setState({ready:true})
				}
			}
		})
	}

	render() {
		const {currentCompetency,currentQuestion,progress,levelmin,levelmax, ready} = this.state;
		const {interviews, questions} = this.props;
		const question = questions.getIn([currentCompetency,"questionArray",currentQuestion]);
		let answers=[];
		console.log(question, questions, ready)
		if(!question || !ready){
			return 	(<Loader
			loading={true} progress={null} onCancel={null}/>
			)
		}
		if(question.get("type")=="simple"){
			answers= question.get("answers")
							.mapEntries( ([ k, v ]) => [ k, v.set("answerId",k) ])
													.toList();
			if (answers.size >0){ 
				if(currentQuestion==1 ){
					answers = answers.slice().filter((a)=> a.get("level") >= levelmin && a.get("level") <= levelmax)				
				}			
				answers = answers.sortBy(()=>Math.random())
			}
			return <Question 
			  back={this._backQuestion}
				progress={progress} 
				question={question} 
				answers={answers} 
				next={this._next} 
				setAnswer={currentQuestion==0?this._calibrate: this._setAnswer}/>
		}else if (question.get("type") =='text') {
				return <QuestionText
				back={this._backQuestion}
				progress={progress} 
				question={question} 
				answers={answers} 
				next={this._next} 
				lastAnswer={this.lastAnswer}
				setAnswer={this._setAnswerText}/>
		}else if (question.get("type") =='audio') {
			 return <QuestionAudio
				  back={this._backQuestion}
					uploadVideo={this.uploadVideo}
					progress={progress} 
					question={question} 
					answers={answers} 
					next={this._next} 
					lastAnswer={this.lastAnswer}
					/>
		}else {
			return <QuestionVideo
					key={question.get("questionId")}
				  back={this._backQuestion}
					uploadVideo={this.uploadVideo}
					progress={progress} 
					question={question} 
					answers={answers} 
					next={this._next} 
					setAnswer={this._setAnswer}
					lastAnswer={question.get("competencyId")== "KQ" ? 0: this.lastAnswer}
					/>
		}
	}
	_backQuestion() {
		const {currentQuestion} = this.state;
		let nextQuestion = currentQuestion-1;
		this._changeQuestion(nextQuestion);
	}
	_next() {
		const {currentQuestion} = this.state;
    let nextQuestion = currentQuestion+1;
		this._changeQuestion(nextQuestion);
	}
	_changeQuestion(nextQuestion){
		const {currentQuestion, currentCompetency,progress} = this.state;
    const {questions} = this.props;

		let nextCompentency= currentCompetency;
		let newProgress = progress;
    if(nextQuestion >= questions.getIn([currentCompetency,"questionArray"]).size) {
      nextQuestion=0;
			nextCompentency = currentCompetency+1;
			if(nextCompentency >= questions.size) {
				this.props.interviewAction.saveAnswers({
					interviewId: this.props.selectedInterview.get("uuid"),
				});
				console.log("Go feedback manager")

				return this.goTo("feedbackManager");
			}
		}
		if(nextQuestion < 0) {
			nextCompentency = currentCompetency-1;
			if(nextCompentency < 0){
				console.log("Go home")
				return this.goTo("home");
			}
			nextQuestion= questions.getIn([currentCompetency-1,"questionArray"]).size-1;
		}
		if(( nextQuestion+(nextCompentency*10)) > ( currentQuestion+(currentCompetency*10))) {
			newProgress = (progress) + (1 / this.total)
			// Save each answer set
			this.props.interviewAction.saveAnswers({
				interviewId: this.props.selectedInterview.get("uuid"),
			});
		}else{
			newProgress = (progress) - (1 / this.total)
		}
		this.setState({
			currentQuestion:nextQuestion,
			currentCompetency: nextCompentency,
			progress: newProgress
		});
	}
	_setAnswer(answer) {
		this.props.interviewAction.setAnswer(answer);
		this.setState({lastAnswer:answer.answer.level})
	}	
	_setAnswerText(answer) {
		this.props.interviewAction.setAnswer(answer);		
	}
	uploadVideo(payload){
		payload.interviewId = this.props.selectedInterview.get("uuid");
		this.props.interviewAction.uploadVideo(payload);
	}

  get total(){
    if(!this._total){
      const {questions} = this.props;
      this._total = questions.reduce((acc,curr) => {
        return acc + curr.get("questionArray").size;  
      },0);
    }
    return this._total;
	} 
	get lastAnswer() {
		let level = 0
		if(this.props.answers){
			let competenciesKeys = this.props.answers.keySeq().toArray()
			let lastCompetencyKey = competenciesKeys[competenciesKeys.length -1];
			let answerKeys =  this.props.answers.getIn([lastCompetencyKey]).filter((e)=>{
				return e.get("text")==null
			}).keySeq().toArray()
			let lastAnswerKey = answerKeys[answerKeys.length-1];
			level = this.props.answers.getIn([lastCompetencyKey, lastAnswerKey, 'level']);
			if (!level){
				level = 0;
			}
			
		}
		return level;
	}

	_back(){
		this.props.navigation.goBack();
	}
	_calibrate(answer){
    //Calibrate the answers for this competency
    //If red flag
    this.setState({
      levelmin: answer.answer.levelmin,
      levelmax: answer.answer.levelmax
    });
    this._setAnswer(answer);
  }

	goTo(name,payload) {
		this.props.actions.push(name);		
	}

}

const mapStateToProps = (state) => {

  return {
  	questions: state.interview.get("questions"),
		interviews: state.interview.get("interviews"),
		selectedInterview: state.interview.get("selectedInterview"),
		answers: state.interview.get("answers")
	};
}

const mapDispatchToProps = (dispatch) => {
  return {
	  interviewAction: bindActionCreators({getInterviews,getQuestions,setAnswer,saveAnswers,uploadVideo}, dispatch),
	  actions: bindActionCreators({ push }, dispatch) 
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InterviewManager)