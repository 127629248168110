import React, { Component } from 'react';
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import {ToastContainer, toast } from 'react-toastify';
import { push, goBack } from 'react-router-redux';
import Inmutable from 'immutable';

import closeIcon from '../../images/close.svg';
import HInput from '../../components/hinput';
import HButton from '../../components/hbutton';
import HSelectCircle from '../../components/hselectCircle';
import HCheckbox from '../../components/hcheckbox';
import HCalibrate from '../../components/hcalibrate';
import HToggle from '../../components/htoggle';

import lineLightGray from '../../images/lineLightGray.svg';
import techIcon from '../../images/roles/tech.svg';
import salesIcon from '../../images/roles/sales.svg';
import hrIcon from '../../images/roles/hr.svg';
import developIcon from '../../images/roles/develop.svg';
import generalIcon from '../../images/roles/general.svg';

import techWhiteIcon from '../../images/roles/techWhite.svg';
import salesWhiteIcon from '../../images/roles/salesWhite.svg';
import hrWhiteIcon from '../../images/roles/hrWhite.svg';
import developWhiteIcon from '../../images/roles/developWhite.svg';
import generalWhiteIcon from '../../images/roles/generalWhite.svg';

import globalIcon from '../../images/scope/global.svg';
import localIcon from '../../images/scope/local.svg';
import regionalIcon from '../../images/scope/regional.svg';

import globalWhiteIcon from '../../images/scope/globalWhite.svg';
import localWhiteIcon from '../../images/scope/localWhite.svg';
import regionalWhiteIcon from '../../images/scope/regionalWhite.svg';

import pencilIcon from '../../images/pencil.svg';
import settingsIcon from '../../images/settings.svg';

import {REQUEST_POSITIONS,REQUEST_NEW_VACANCY,REQUEST_CONFIG,REQUEST_UPDATE_VACANCY} from '../../reducers/vacancy.js';
import {SAVE_CONFIG} from '../../reducers/profile.js';
import './index.css';

const levels=[0,1,2,3,4,5,6,7,8,9,10];
const sections={
	"new":{text:"Let's get started!  It's simple.."},
	"segment":{text:"Specify vacant role here:"},
	"config":{supertext:"Herman suggests the following competency set and targets for the position of:",text:null},
	"important":{text:"IMPORTANT"},
	"competencies":{text:"Competencies"},
	"welcome":{text:"Herman"}
}

const areas=[
	{text:"Sales",icon:salesIcon,iconAlt:salesWhiteIcon,key:"sales"},
	{text:"Operations",icon:salesIcon,iconAlt:generalIcon,key:"ops"},
	{text:"Finance",icon:techIcon,iconAlt:techWhiteIcon,key:"fin"},
	{text:"IT",icon:techIcon,iconAlt:techWhiteIcon,key:"tech"},	
	{text:"H.R.",icon:hrIcon,iconAlt:hrWhiteIcon,key:"hr"},
	{text:"Strategy",icon:hrIcon,iconAlt:hrWhiteIcon,key:"strategy"},	
	{text:"Legal & Compliance",icon:hrIcon,iconAlt:hrWhiteIcon,key:"legal"},	
	{text:"Risk Management",icon:hrIcon,iconAlt:hrWhiteIcon,key:"risk"},	
	{text:"Other",icon:developIcon,iconAlt:developWhiteIcon,key:"other"},
];
const scopes=[
	{text:"Global",icon:techIcon,iconAlt:techWhiteIcon,key:"global"},
	{text:"Regional",icon:salesIcon,iconAlt:salesWhiteIcon,key:"regional"},
	{text:"Local",icon:hrIcon,iconAlt:hrWhiteIcon,key:"local"},
];
const competencies= {
	"TL": "Leadership",
	"AO": "Results Orientation",
	"ST": "Strategic Thinking",
	"TC": "Team Work & Collaboration",
	"DO": "Developing Others",
	"EI": "Emotional Intelligence",
	"CO": "Commercial Drive",
	"CM": "Change Management"
}


class NewVacancy extends Component {
	constructor(props){
		super(props);
		this._close = this._close.bind(this);
		this._nextSection = this._nextSection.bind(this);
		this._previousSection = this._previousSection.bind(this);
		this._toggleModifySkills = this._toggleModifySkills.bind(this);
		this._toggleCompetency = this._toggleCompetency.bind(this);
		this._addMoreSettings = this._addMoreSettings.bind(this);
		this._onCalibrate  = this._onCalibrate.bind(this);
		this.renderGeneral = this.renderGeneral.bind(this);
		this.renderSection = this.renderSection.bind(this);
		this.renderSpecify = this.renderSpecify.bind(this);
		this.renderSkills = this.renderSkills.bind(this);
		this.renderLeftButton = this.renderLeftButton.bind(this);
		this.renderImportant = this.renderImportant.bind(this);
		this.renderCompetencies = this.renderCompetencies.bind(this);
		this.renderWelcome = this.renderWelcome.bind(this);
		this.renderButtons = this.renderButtons.bind(this);
		this.onPositionsRequested = this.onPositionsRequested.bind(this);
		this.onPositionsClear = this.onPositionsClear.bind(this);
		this.getSuggestionValue = this.getSuggestionValue.bind(this);
		this.renderSuggestion = this.renderSuggestion.bind(this);
		this.onChangePosition = this.onChangePosition.bind(this);
		this.selectVacantRole = this.selectVacantRole.bind(this);
		this.selectVacantScope = this.selectVacantScope.bind(this);
		this.selectDisclose = this.selectDisclose.bind(this);
		this.selectConfidential = this.selectConfidential.bind(this);
		this._nextSkills = this._nextSkills.bind(this);
		this._nextWelcome = this._nextWelcome.bind(this);		
		this._nextWithoutVideo = this._nextWithoutVideo.bind(this);
		this._nextVerify = this._nextVerify.bind(this);
		this._changeCompany = this._changeCompany.bind(this);
		this._nextSpecify =this._nextSpecify.bind(this);
		
		this.state = {
			visible:props.visible || false,
			ceoLevel:3,
			section:0,
			modify:false,
			skills: [],
			competencies: [],
			positions:[],
			position:"",
			vacant: Math.floor(areas.length/2),
			scope: Math.floor(scopes.length/2),
			confidential: true,
			changed: false,
			role: null,
			company: "",
			config: props.config.getIn(['competencies']) ?  Inmutable.Map().set("competencies", props.config.getIn(['competencies'])): null,
			loadedFromServer: false
		}		
		//this.processVisibility(this.state.visible);
		if(props.vacancy){
			this.state = Object.assign({},this.state, this.componentSetFromServer(props));
		}
	}	
	
	componentWillReceiveProps(newProps){
		console.log("Try get info from props");
		if(newProps.vacancy && newProps.vacancy != this.props.vacancy){
			console.log("Getting info from props");
			this.setState(this.componentSetFromServer(newProps));
			console.log("Props from constructor",  newProps)

		}
		if(newProps.config && newProps.config != this.props.config){
			this.setState({
				config: Inmutable.Map().set("competencies", newProps.config.getIn(['competencies'])),
			})
		}
		
	}	
	componentWillUpdate( nextProps,  nextState){
		this.processVisibility(nextState.visible);
	}

	componentSetFromServer(props){
		let newState = {
			ceoLevel: props.vacancy.getIn(["config","ceo_level"]),
			position: props.vacancy.getIn(["name"]),
			company: props.vacancy.getIn(["company"]),
			vacant: areas.findIndex((a) => a.key == props.vacancy.getIn(["config","area"])),
			scope: scopes.findIndex((s) => s.key == props.vacancy.getIn(["config", "scope"])),
			confidential: props.vacancy.getIn(["confidentiality"]),
			modify: false,			
			loadedFromServer: true,
			id: props.vacancy.getIn(["uuid"])  
		}
		if(props.vacancy.get("competencies")){
			newState['competencies'] =  Inmutable.Map().set("competencies", props.vacancy.get("competencies"))
		}
		return newState;
	}

	render(){
		const {visible,section} = this.state;
		const title = sections[this.props.screen].text || this.state.position;		
		const supertext = sections[this.props.screen].supertext;
		return (
			<div className="background-modal">
				<div className="container newVacancy">
					<div className="" role="document">
						<div className="">
							<div className="">
								<div style={{width:100}}>
									{this.renderLeftButton()}
								</div>
								<div>
									<div className="text text-center" style={{maxWidth:400,margin:"0 auto"}}>
										{supertext}
									</div>
									<h5 className="modal-title margin-top-20 text-center">{title}</h5>
								</div>
								<button onClick={this._close} type="button" className="close" aria-label="Close">
									<span aria-hidden="true">
									<img src={closeIcon}/>
									</span>
								</button>
							</div>
							<div className="">
								{this.renderSection()}
							</div>
							<div className=" center margin-bottom-20">
								{this.renderButtons()}
							</div>
						</div>
						<ToastContainer 
							position="top-right"
							autoClose={5000}
							hideProgressBar={false}
							newestOnTop={false}
							toastClassName="dark-toast"
							progressClassName='transparent-progress'						
							closeOnClick
							pauseOnHover
						/>
					</div>

				</div>
			</div>
		)
	}

	renderSuggestion(suggestion) {
		return (
			<span>{suggestion.get('name')}</span>
		);
	}
	renderSection() {
		const{screen} = this.props;
		switch(screen){
			case "new":
				return this.renderGeneral();
			case "segment":
				return this.renderSpecify();
			case "config":
				return this.renderSkills();
			case "important":
				return this.renderImportant();
			case "competencies":
				return this.renderCompetencies();
			case "welcome":
				return this.renderWelcome();			
		}
	}
	renderButtons(){
		const{screen} = this.props;
		switch(screen){
			case "new":
				return (
					<HButton type="primary" className="max-300" onClick={this._nextSection}>Next</HButton>
				)
			case "segment":
				return (
					<HButton type="primary" className="max-300" onClick={this._nextSpecify}>Next</HButton>
				)
			case "config":
				return (
					<HButton type="primary" className="max-300" onClick={this._nextVerify}>Next</HButton>
				)
			case "important":
				return (
					<div style={{textAlign:"center"}}>
						<HButton type="primary" className="max-300" onClick={this._previousSection}>Go back, need to change something</HButton>
						<HButton type="plain" className="max-300" onClick={this._nextWelcome}>I am aware, continue with my changes</HButton>
					</div>
				)
			case "competencies":
				return (
					<HButton type="primary" className="max-300" onClick={this._nextSkills}>Next</HButton>
				)
		
			case "welcome":
				return (
					
						<HButton type="primary" className="max-300" onClick={this._nextWithoutVideo}>Start Interview</HButton>						
					
				)
		}
	}
	renderLeftButton(){
		const{screen} = this.props;
		switch(screen){
			case "config":
				return !this.state.modify?null:(
					<div>
						<HButton type="plain" onClick={this._toggleModifySkills}>Undo</HButton>
					</div>
				)
			default:
				return null;
		}
	}
	renderGeneral() {
		const {ceoLevel,positions,position,company} = this.state;
		const {companies} = this.props;
		return (
			<div className="text-center">
				<div>
					<label className="default ">
						Role of Vacant position
					</label>
				</div>
				<div className="margin-top-20 position">
					<HInput className="max-300"  value={position} placeholder="Example: Technology Director" onChange={this.onChangePosition}/>
				</div>
				<div className="margin-top-20">
					<label className="secondary ">
						For: <input className="inline-input" value={company} onChange={this._changeCompany}/>
					</label>
				</div>
				<div >
					<img src={lineLightGray} style={{width:"100%",height:2}} className="max-400"/>
				</div>
				<div>
					<label className="default ">
						How many levels under CEO is this vacant role?
					</label>
				</div>
				<div className="margin-top-20">
					<div className="circleOptions numbers">
					{
						levels.map((e,i)=>(
							<span key={i} onClick={this._selectCeoLevel.bind(this,e)} className={"circleSelection circleSingleCharacter "+(ceoLevel==e?"active":"")}>
								{e}
							</span>
						))
					}
					</div>
				</div>
			</div>
		)
	}
	renderSpecify(){
		const{vacant,scope,confidential} = this.state;
		return(
			<div>
				<div className="text-center">
					<label className="default ">
						Area
					</label>
				</div>
				<HSelectCircle options={areas} value={vacant} onChange={this.selectVacantRole} className='area'/>
				<div className="text-center">
					<img src={lineLightGray} style={{width:"100%",height:2}} className="max-400"/>
				</div>
				<div className="text-center">
					<label className="default ">
						Scope
					</label>
				</div>
				<HSelectCircle options={scopes} value={scope} onChange={this.selectVacantScope} />
				<div className="text-center">
					<img src={lineLightGray} style={{width:"100%",height:2}} className="max-400"/>
				</div>
				<div className="text-center">
					<label className="default ">
						Confidentiality
					</label>
				</div>
				<div style={{display:"flex",justifyContent:"center"}}>
					<HCheckbox checked={!confidential} onChange={this.selectDisclose}>Disclose</HCheckbox>
					<HCheckbox checked={confidential} onChange={this.selectConfidential}>Confidential</HCheckbox>
				</div>
			</div>
		)
	}

	renderSkills(){
		const{modify} = this.state;
		return (
			<div>
				<div className="text-center">
					<img src={lineLightGray} style={{width:"100%",height:2}} className="max-400"/>
				</div>
				{!modify?(
					<div className="text-center">
						<div className="grayBox">
							I make recommendations based on the input of experts and my patented AI / Machine Learning algorithms, take advantage
						</div>
					</div>
				):(
					<div className="text-center">
						<div className="pinkBox">
							Herman does not encourage changes, define the competencies carefully. Your changes will significantly affect the final results and analysis of candidates						</div>
					</div>
				)}
				{!modify?(
					this.props.config.get("competencies") && this.props.config.get("competencies").filter((v)=> v.get("enabled")).entrySeq().map(([i,v]) =>{						
						return <HCalibrate steps={6} payload={i} onChange={this._onCalibrate} description={competencies[i]} progress={Math.floor(v.get("value")*100/6)} initial="Less" final="More"/>
					})
				):(
					this.state.config.get("competencies") && this.state.config.get("competencies").filter((v)=> v.get("enabled")).entrySeq().map(([i,v]) =>{										
						return	<HCalibrate  steps={6} payload={i} onChange={this._onCalibrate} description={competencies[i]} progress={Math.floor(v.get("value")*100/6)} initial="Less" final="More"/>
					})
				)}
				{/*<div className="text-center">
					{!modify  && this.props.config?(
						<HButton type="only-border" className="max-300" onClick={this._toggleModifySkills}>
							<img src={pencilIcon}/>
							<span> Modify</span>
						</HButton>
					):(
						<HButton type="only-border" className="max-300" onClick={this._addMoreSettings}>
							<img src={settingsIcon}/>
							<span> Add more competencies</span>
						</HButton>
					)}
				</div>*/}
			</div>
		)
	}

	renderImportant(){
		return (
			<div>
				<div className="text-center">
					<div className="max-400 msg">
						You have made SIGNIFICANT changes to the competency set and its targets. This could result in material deviations in Herman's analysis of your candidates
					</div>
				</div>
			</div>
		)
	}

	renderCompetencies() {
		const {config} = this.state;
		return (
			<div>
				<div className="max-400" style={{margin:"0 auto"}}>
					{config && config.get("competencies") && config.get("competencies").entrySeq().map(([i,e]) =>(		
						<HToggle key={i} label={competencies[i]} enabled={e.get("enabled")} payload={i} onChange={this._toggleCompetency}/>
					))}
				</div>
			</div>
		)
	}


	renderWelcome() {
		return (
			<div>
				<div className="text-center">
					<div className="max-400 msg">
						Observes and comprehends your answers…
					</div>
					<div className="max-400 msg margin-top-20">
						Position
					</div>
				</div>
				<div className="text-center margin-top-20">
					<label className="secondary ">
						{this.state.position}
					</label>
				</div>
				<div className="margin-top-20 text-center">
					<div className="max-400 msg">
						Aproximately duration: 25 - 30 min.
					</div>				
				</div>
			</div>
		)
	}
	_changeCompany(ev){
		this.setState({
			company: ev.target.value
		}) 
	}
	onChangePosition(value) {
		this.setState({
			position: value
		});
		
	}
	onPositionsRequested() {
		this.setState({
			positions: this.props.positions.filter((p)=>
				p.get("name").toLowerCase()
				.indexOf(this.state.position.toLowerCase())>=0 ).toArray()
		});
	}

	onPositionsClear() {
		this.setState({
			positions:[]
		});
	}
	getSuggestionValue(suggestion){
	
		this.setState({
			//skills: suggestion.get("competencies").toJS(),
			role: suggestion
		})
		return suggestion.get('name');
	}

	_onCalibrate(value,i) {
		if(this.state.modify){
			let newSkills = this.state.config;
			console.log(value)
			this.setState({
				config: newSkills.setIn(["competencies",i,"value"],value),
				changed: true
			})
		}
	}

	_toggleModifySkills(){
		let modify = !this.state.modify;
		let newState={
			modify
		}
		if(modify){
			newState.config = this.props.config
			newState.changed = false
		}
		this.setState(newState);
	}

	_toggleCompetency(value, index){
		const newCompetencies = this.state.config;
		this.setState({
			config: newCompetencies.setIn(["competencies",index,"enabled"],value),
			changed: true
		})
	}

	_addMoreSettings() {
		this.props.actions.push("/competencies")
	}

	_nextSection() {
		const {position, company, ceoLevel} = this.state;
		if(!this.state.position){
			toast('Please select a role first');
			return;
		}
		if(!this.state.company){
			toast('Please select a company first');
			return;
		}
		this.props.profileActions.SAVE_CONFIG({
			config:{
				ceo_level: ceoLevel
			},
			name: position,
			company		
		})
		this.props.actions.push("/segment")		

	}
	_previousSection() {
		this.props.actions.goBack()
	}
	_selectCeoLevel(e) {
		this.setState({
			ceoLevel: e
		});
	}

	_close(){
		this.props.actions.push("/home")
	}

	processVisibility(visible) {
		/*if(visible) {
			document.getElementById("backdrop").className = "modal-backdrop show";
			document.getElementById("main").classList.add("modal-open");

		}else{
			document.getElementById("backdrop").className = "";
			document.getElementById("main").classList.remove("modal-open");
		}*/
	}
	selectVacantRole(value) {
		this.setState({
			vacant: value 
		})
	}
	selectVacantScope(value) {
		this.setState({
			scope: value
		})
	}
	selectDisclose() {
		this.setState({
			confidential:false
		})
	}
	selectConfidential() {
		this.setState({
			confidential:true
		})
	}
	_nextSkills(){
		this.props.actions.goBack()
	}
	_nextWelcome() {
		this.props.actions.push("/welcome")
	}
	_nextSpecify() {
		this.props.vacancyActions.REQUEST_CONFIG({
			ceo_level: this.state.ceoLevel,
			scope: scopes[this.state.scope].key,
			area: areas[this.state.vacant].key
		})		
		this.props.profileActions.SAVE_CONFIG({
			config:{
				scope:scopes[this.state.scope].key,
				area:areas[this.state.vacant].key
			},
			confidentiality: this.state.confidential
		})
		this.props.actions.push("/config")
	}
	_nextWithoutVideo(){
		this.createVacancy()
		.then(()=>{
			this.props.actions.push("/interview")
		})

	}

	createVacancy() {
		return new Promise((resolve)=> {
			const request = {
				vacancy:{		
					name: this.state.position,
					config: this.props.config.get("uuid"),
					company: this.state.company,
					confidentiality: this.state.confidential,
					competencies: !this.state.modify ? this.props.config.get("competencies").toJS() :  this.state.config.get("competencies").toJS()
				},
				onComplete:()=>{
					resolve();
				}
			};
			if(this.state.loadedFromServer){
				request.uuid = this.props.vacancy.get("uuid");
				this.props.vacancyActions.REQUEST_UPDATE_VACANCY(request);
			}else {
				this.props.vacancyActions.REQUEST_NEW_VACANCY(request);
			}
		})
	}
	_nextVerify() {
		if(this.state.changed) {
			this.props.actions.push("/important")

		}else{
			this.props.actions.push("/welcome")
		}
	}
	clear() {
		this.setState({
			ceoLevel:3,
			section:0,
			modify:false,
			skills: [],
			competencies: [],
			positions:[],
			position:"",
			role:null,
			vacant: Math.floor(areas.length/2),
			scope: Math.floor(scopes.length/2),
			confidential: true,
			changed: false
		});
	}
	
}

function mapStateToProps(state) {
	
  return {
		positions: state.vacancy.get('positions'),
		companies: state.vacancy.get("companies"),
		config: state.vacancy.get("config"),
		vacancy: state.profile.get("vacancy"),		
  }
}
function mapDispatchToProps(dispatch) {
 	return { 
		actions: bindActionCreators({ push, goBack }, dispatch),
		vacancyActions:  bindActionCreators( {REQUEST_POSITIONS,
												REQUEST_NEW_VACANCY,
												REQUEST_CONFIG,
												REQUEST_UPDATE_VACANCY} ,dispatch),
		profileActions: bindActionCreators({SAVE_CONFIG}, dispatch)
 	}
}
function mergeProps(stateProps, dispatchProps, ownProps) {
	return Object.assign({}, ownProps, stateProps, dispatchProps);
}	

export default connect(mapStateToProps,mapDispatchToProps,mergeProps,{ withRef: true })(NewVacancy)

