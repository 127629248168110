import React, { Component } from 'react';
import checkboxOn from '../../images/checkboxOn.svg';
import checkboxOff from '../../images/checkboxOff.svg';
import './index.css';

export default class HCheckbox extends Component {
	constructor(props){
		super(props);
		this._check = this._check.bind(this);
	}

	componentWillUpdateProps(newProps) {
		this.setState({
			checked: newProps.checked
		});
	}

	render() {
		const {children,checked} = this.props; 
		const image = checked ? checkboxOn: checkboxOff;

		return (
			<div className="checkbox" onClick={this._check}>
				<img src={image}/>
				<div>
					{children}
				</div>
			</div>
		)
	}
	_check() {
		this.props.onChange();
	}
}