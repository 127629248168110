import React, { Component } from 'react';

export default class HSelectCircle extends Component {
	constructor(props){
		super(props);

	}

	render() {
		const {options,className,value} = this.props;
		return(
			<div className={`circleOptions ${className}`}>
				{options.map((e,i)=>(
					<div key={i} className="circleOption" onClick={this.select.bind(this,i)}>
						<div className={"circleSelection medium "+(i==value?"active":"")}>
							<img src={(i==value?e.iconAlt:e.icon)}/>
						</div>
						<div className="text-center circleText ">
							{e.text}	
						</div>
					</div>
				))}
			</div>
		)
	}
	select(index){
		this.props.onChange(index);
	}
}