import React, { Component } from 'react';
import './index.css';
export default class HButton extends Component {
	render() {
		const {children,type,onClick,className, disabled} = this.props; 

		return (
			<button className={`hbutton ${type} ${className}`} onClick={onClick} disabled={disabled}>
				{children}
			</button>
		)
	}
}