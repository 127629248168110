import request from 'superagent';
const Store = {
    get:(term) => new Promise((resolve)=> resolve(localStorage.getItem(term)))
}

export default {
	requestLogin: (username, password) => 
		request.post(window.API_BASE+"/oauth/token/")
					.type('form')
					.set('Authorization', 'Basic '+window.CLIENT_CREDENTIALS)
					.send({ grant_type: 'password' })
					.send({ email: username })
					.send({ password: password })
					.send({ scope: "write" }),
	requestLoginLinkedin: (token) =>
		request.post(window.API_BASE+"/oauth/linkedinprofile/token/")
					.type('form')
					.set('Authorization', 'Basic '+window.CLIENT_CREDENTIALS)
					.send({ grant_type: 'password' })
					.send({ token_app: token })
					.send({ scope: "write" }),
	requestSignup: (data) =>
		request.post(window.API_BASE+"/api/assessment/signup/")
					.send(data),
	requestSignupLinkedin: (token) =>
		request.post(window.API_BASE+"/api/assessment/linkedin/signup/")
					.type('json')
					.send({token_app:token}),
	doGetProfile: ()=>
		Store.get('accessToken')
			.then((accessToken)=>
				request.get(window.API_BASE+"/api/assessment/profile/")
				.set('Authorization', `Bearer ${accessToken}`)
				.type('json')			
			),				
}