import React, { Component } from 'react';
import { combineReducers, applyMiddleware, createStore, compose } from 'redux';
import { ConnectedRouter, routerReducer, routerMiddleware } from 'react-router-redux'
import {createBrowserHistory} from 'history'
import { Provider } from 'react-redux';
import createSagaMiddleware from 'redux-saga'
import rootSaga from './sagas'
import {routes} from './routes';
import session from './reducers/session'
import profile from './reducers/profile'
import vacancy from './reducers/vacancy'
import interview from './reducers/interview'

import './App.css';
const history = createBrowserHistory()
const middleware = routerMiddleware(history)
const sagaMiddleware = createSagaMiddleware()


const reducers = combineReducers({
  router: routerReducer,  
  profile,
  vacancy,
  session,
  interview
});


const composeEnhancers = compose;
const store = createStore(
  reducers,
  composeEnhancers(  
    applyMiddleware(sagaMiddleware, middleware)
  )
);

sagaMiddleware.run(rootSaga);


class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          {routes}
        </ConnectedRouter>
      </Provider>
    );
  }
}

export default App;
