import React, { Component } from 'react';
import {Style} from './style';
import HButton from '../../components/hbutton';
import Header from './header';
import CheckWhite from '../../images/checkWhite.png';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import Speech from '../../images/speech.png'
import Headphones from '../../images/headphones.png'
import Loader from './loader'
import Mic from '../../images/mic.png'
import {ToastContainer, toast } from 'react-toastify';
import { ReactMic } from 'react-mic';


const TIMEOUT_PREVIEW = 10;
const TIMEOUT_RECORD = 120;
/*
	States
	0 Inital state
	1 Show camera and start timer, the timer should start the recording
	2 Recording state
*/

export default class QuestionAudio extends Component{
	constructor(props) {
		super(props);
		this._next = this._next.bind(this);
		this._back = this._back.bind(this);
		this._view = this._view.bind(this);
		this._cancel = this._cancel.bind(this);
		this._submit = this._submit.bind(this);
		this.onStop = this.onStop.bind(this);
		this.onData = this.onData.bind(this);
		//this.prepareAudio = this.prepareAudio.bind(this);
		this.state = {
			step: 0,
			timer1:0,
			timer2:0,
			audioValue: 0,
			loading:false,
			showPreview: false,
			audioUri: null,
			hasPermission: false,
			progressUpload:0,
			recording: false
		}
		this.audioBlob = null;

		
	}
	componentDidMount(){				
		
	}
	/*
	prepareRecording(){

		return AudioRecorder.requestAuthorization().then((isAuthorised) => {
			this.setState({ hasPermission: isAuthorised });

			if (!isAuthorised) return;

			AudioRecorder.prepareRecordingAtPath(this.audioPath, {
				SampleRate: 44100.0,
				Channels: 2,
				AudioQuality: "High",
				AudioEncoding: "aac",
				MeteringEnabled: true
			});

			AudioRecorder.onProgress = (data) => {				
				let audioValue = data.currentMetering+160;
				audioValue = audioValue * 100 /160 ;
				this.setState({
					audioValue: audioValue
				})
			};
			AudioRecorder.onFinished = (data) => {
				this.prepareAudio(this.audioPath);
			};
		});
	}*/
	onStop(blob){
		console.log("Stop recording", blob)
		this.audioBlob = blob.blob				
		this.setState({
			audioUri: blob.blobURL
		});
	}
	onData(){
		//console.log("On data")
	}
	render() {
		const{question,progress,answers}= this.props;
		const{step,timer1,timer2,audioUri, progressUpload, audioValue} = this.state;

		return (
			<div style={{flex:1, height:"100%", display:"flex", flexDirection:"column",backgroundColor:"white"}}>
				
        		<audio ref={(audioControl)=>{
					this.audioControl =audioControl
					
				}}  src={this.state.audioUri}/>
				<ToastContainer 
							position="top-right"
							autoClose={5000}
							hideProgressBar={false}
							newestOnTop={false}
							toastClassName="dark-toast"
							progressClassName='transparent-progress'						
							closeOnClick
							pauseOnHover
						/>
				<Loader loading={this.state.loading} progress={progressUpload} onCancel={this._cancel} />
				
					
				<Header progress={progress} hideProgress={step>0} back={this._back}>
					
				</Header>
				<div>

				</div>
				{step ==0?(
					[
						<div key="0" style={{display:"flex", justifyContent:"center", marginTop:20,alignItems:"center",marginLeft:20,marginRight:20}}>
							<span style={{...Style.blackText,textAlign:"center",fontSize:18,fontWeight:"500"}}>{question ? question.getIn(['questions',this.props.lastAnswer]): ''}</span>
						</div>,
						<div key="1" style={{marginTop:20,alignItems:"center", display:"flex", flexDirection:"column"}}>
							<div style={{alignItems:"center",marginTop:20}}>
								<img src={Speech} style={{width:10,height:20,width:25, objectFit: "contain"}}  />
							</div>
							<span style={{...Style.center,...Style.blackText,marginTop:20,fontSize:16,marginLeft:10,marginRight:10}}>
								Record your message, be concise and as clear as possible in the audio answer. You only have one opportunity to record your message.
							</span>
							<div style={{alignItems:"center",marginTop:20}}>
								<img src={Headphones} style={{width:10,height:20,width:25, objectFit: "contain"}} />
							</div>
							<span style={{...Style.center,...Style.blackText,fontSize:16,marginLeft:10,marginRight:10,marginTop:20}}>
								Do not forget to use your headphones with integrated microphone.
							</span>
						</div>,
						<div style={{...Style.horizontalCenterAlign,position:"absolute",bottom:10, width: "100%"}}>
							<div style={{width:320, margin:"0 auto"}}>
								<HButton type="transparent"  onClick={this._next}>Next</HButton>
							</div>
						</div>
					]
				):null}
				{step >=1?(
					<div style={{display:"flex", flex:"auto", width:"100%",flexDirection:"column",justifyContent: 'space-between',alignItems: 'center' , backgroundColor: "black"}}  >
						<div style={{marginTop:10,marginLeft:20,marginRight:20}}>
							{step ==1?(
								<div style={{backgroundColor: "rgba(0,0,0,0.7)",borderRadius:100}}>
									<span style={{...Style.whiteText,marginRight:20,marginLeft:20,marginTop:10,marginBottom:10,fontSize:14}}>
										Try to speak clearly as posible.
									</span>
								</div>
							):null}
							{step ==2?(
								<div style={{backgroundColor: "rgba(0,124,255,0.7)",borderRadius:100}}>
									<span style={{...Style.whiteText,marginRight:20,marginLeft:20,marginTop:10,marginBottom:10,fontSize:14}}>
										{question ? question.getIn(['questions',this.props.lastAnswer]): ''}
									</span>
								</div>
							):null}
						</div>
					
						{step == 1?(
							<div>
								<span style={{...Style.whiteText,fontSize:32}}>
									{ TIMEOUT_PREVIEW - timer1 }
								</span>
							</div>
						):null}
						
						{step <= 1?(
							<div>
								<span style={{...Style.whiteText,fontSize:32}}></span>
							</div>
						):null}
						{step ==2  ?(
							/*<div style={{
								width: 50 + (audioValue/100 *100),
								height: 50+ (audioValue/100 *100),
								borderRadius: (50 + (audioValue/100 *100)) /2,
								backgroundColor: '#0080FF',
								justifyContent: 'center',
								alignContent: 'center',
								alignItems: 'center'
							}} >
								<img src={Mic} style={{width:50,height:50, objectFit: "contain"}}  />
							</div>*/
							<ReactMic
								record={this.state.recording}								
								onStop={this.onStop}
								onData={this.onData}
								strokeColor="#0072ff"
								backgroundColor="#000000" />	
						):null}
						{step ==2?(
							<div style={{flexDirection:"row",  display:"flex",  justifyContent:"space-between", width:"calc(100% - 80px)"}}>
								<div style={{width:100,height:50, marginRight:20, top:10}}>
									<HButton type="primary"  onClick={this._view} disabled={audioUri==null}>
										Play
									</HButton>
								</div>
								<div onClick={()=> this.restartTimer()} style={{width:100, height:100,position:"relative", top:-35, cursor:"pointer"}}>
									<CircularProgressbarWithChildren
										styles={buildStyles({
											// Text size
											textSize: '16px',
											// Can specify path transition in more detail, or remove it entirely
											// pathTransition: 'none',
										
											// Colors
											pathColor: `#7ED321`,
											textColor: '#fff',
											trailColor: '#FF0000',
											backgroundColor: '#B1B1B1',
										})}
										size={64}
										width={6}
										maxValue={TIMEOUT_RECORD}
										value={timer2}
										rotation={0}
										tintColor="#FF0000"
										fillColor="#B1B1B1"
										style={{backgroundColor:"transparent",marginBottom:20}}
										backgroundColor="#7ED321" >
										{timer2 > 0 ? 
											<span style={{color:"#FFFFFF",fontSize:15, position:"absolute", top:32, left: 15, width:67,display:"block", textAlign:"center"}}>
												Stop
											</span>
										:(
											<span style={{color:"#FFFFFF",fontSize:15, position:"absolute", top:32, left: 15, width:67,display:"block", textAlign:"center"}}>
												Record
											</span>
										)}
									</CircularProgressbarWithChildren>
							 </div>
							 <div style={{width:100,height:50, marginLeft:20, top:10}}>								
							 	<HButton type="primary"  onClick={this._submit} disabled={audioUri==null}>
									Submit
								</HButton>
							 </div>
							</div>
						 ):null}
					</div>
				):null}
			</div>
		)
	}
	_back() {
		switch(this.state.step){
			case 0:
				return this.props.back();
			case 1:
				clearInterval(this.timer);
				this.setState({
					step:0,
					timer1: 0
				});
				break;
			case 2:
				this._stopRecord();
				this.setState({
					step:0,
					timer2: 0
				});
		}
	}	
	_stopRecord() {
		clearInterval(this.clock);
		this.clock = 0;
		this.setState({
			recording:false,
			timer2:0
		})
	}

	_next() {
    //this.props.next();
    this.setState({
    	step:1
    });
    let initTime = new Date().getTime();
    this.timer =setInterval(()=>{
			let newTime = new Date().getTime();
			let seconds = Math.round( (newTime - initTime) / 1000);
			this.setState({
				timer1: seconds
			});
			if(seconds>=TIMEOUT_PREVIEW){
				clearInterval(this.timer);
				this.setState({
					step:2
				})
				this.startTimer();
			}
		},500);
	}
	_cancel() {
		this.setState({
			loading:false
		})
		if(this.request){
			try{
				this.request.abort()
			}catch(e) {
				console.log("Error canceling request");
			}
		}
	}
	_submit() {
		if(this.clock!=0){
			toast("The audio is still recording, please stop your recording and try again.", 1000);
			return;
		}
		if(this.audioBlob && !this.clock) {
			this.setState({
				loading:true
			},()=>{
				this.props.uploadVideo({
					videoId: this.props.question.get("questionId"),
					video: this.audioBlob,
					type: "audio",
					callback:(result)=>{
						this.setState({
							loading:false
						})
						if(result){
							this.props.next();
						}else{
							toast("There was an error uyploading your answer, please try again", 1000);
						}
						//resolve(result);
					},
					onUploading: (request)=>{
						this.request = request
					},
					onProgress:(percent)=>{
						this.setState({
							progressUpload: Math.round(percent)
						})
					}
				});
			});
		}else{

		}
	}
	_view() {
		/*if(this.clock!=0){
			toast("The audio is still recording, please stop your recording and try again.", 1000);
			return;
		}*/
		this.audioControl.play();
		/*var sound = new Sound(this.audioPath, '', (error) => {
			if (error) {
				console.log('failed to load the sound', error);
			}
		});
		setTimeout(() => {
			sound.play((success) => {
				if (success) {
					console.log('successfully finished playing');
				} else {
					console.log('playback failed due to audio decoding errors');
				}
			});
		}, 500);*/
		//this.setState({showPreview:true});
	}
	restartTimer() {
		if(this.clock==0){
			this._next();
		}else{
			this._stopRecord();
		}
	}
	startTimer() {
		if(!this.clock){
			console.log("Start recording")
			let start = new Date().getTime();
			this.clock = 0;
			this.setState({
				recording:true,
				audioUri: '',
				timer2:0
			}, ()=>{
				
				this.clock = setInterval(()=>{ 
					let time = (new Date().getTime() - start) /1000;
					if(time>=TIMEOUT_RECORD){
						console.log("Stoping record")
						this.setState({recording:false,timer2:0}, ()=>{
							clearInterval(this.clock);
						})
						return //this.props.next();
					}
					this.setState({
						timer2: time * 100 / TIMEOUT_RECORD
					});
				}, 500);	
			})	
		}
	}

}