import React, { Component } from 'react';
import {Style} from './style';
import Slider from "react-slick";
import HButton from '../../components/hbutton';
import Header from './header';
import CheckWhite from '../../images/checkWhite.png';
import AnswerSelector from './answerSelector.js';
export default class Question extends Component{
	constructor(props) {
		super(props);
		this.chooseItem = this.chooseItem.bind(this);
		this.renderView = this.renderView.bind(this);
		this._next = this._next.bind(this);
		this.state = {
			choose: -1
		}
	}
	componentWillReceiveProps(newProps){
		if (newProps.question && this.props.question && newProps.question.get("description") != this.props.question.get("description")) {
			this.setState({
				choose: 0 
			})
			//this._carousel.slickGoTo(0);
		}
	}
	render() {
		const{question,progress,answers}= this.props;
		let doubleAnswers = []
		if (answers && answers.count() > 0){
			doubleAnswers = [...answers.toJS()]
		}		
		return (
			<div style={{flex:1, flexDirection:"column",backgroundColor:"white"}}>
				<Header progress={progress} back={this.props.back}>
					
				</Header>
				<div>

				</div>
				<div style={{marginTop:20,alignItems:"center",marginLeft:20,marginRight:20}}>
					<span style={{...Style.blackText,textAlign:"center",fontSize:18,fontWeight:"500"}}>{question ? question.get('description'): ''}</span>
				</div>
				<div style={{marginTop:20,alignItems:"center"}}>
					<span style={{...Style.center,...Style.blackText,fontSize:16,marginLeft:10,marginRight:10}}>Select the option that best describes you in your present or near past roles</span>
				</div>
				<div style={{marginTop:20,left:0,marginBottom:20, width:window.innerWidth}}>
					{question? (
						<AnswerSelector
							ref={(carousel) => { this._carousel = carousel; }}
							centerMode={true}
							slidesToShow={1}
							afterChange={this.chooseItem}
							arrows={false}
							>
							{ doubleAnswers.map((e,i)=> this.renderView(e,i) )}
						</AnswerSelector>
	        		):null}
				</div>
				<div style={{...Style.horizontalCenterAlign,position:"absolute",bottom:10, width: "100%"}}>
					<div style={{width:320, margin:"0 auto"}}>
						<HButton type="transparent" onClick={this._next}>
							Save and submit
						</HButton>
					</div>
				</div>
			</div>
		)
	}
	
	chooseItem(index) {

		this.setState({
			choose:index
		}) 
	}

	_next() {
		if(this.state.choose ==-1){
			return;
		}
		let {question,answers} = this.props;
		const {choose} = this.state;
    	this.props.setAnswer( {
			answer: answers.get(choose % answers.count())
							.set("competencyId",question.get("competencyId"))
							.set("questionId",question.get("questionId"))
							.toJS()
    	});
    	this.props.next();
	}

	renderView( item, index){
		const description = item.description;
		const size = this.props.answers.count()
		let background = index == this.state.choose ?  "#007CFF" : "#EBEBEB";
		let foreground = index == this.state.choose ?  "#FFFFFF" : "#000000";
		
		return (
			<div>
				<div key={index} style={{display:"flex",flexDirection:"column", backgroundColor:background,alignItems:"center",justifyContent:"flex-start",height:120,width: "calc(100% - 20)", maxWidth:600, borderRadius:5, marginLeft:10, marginRight:10, paddingLeft:10, paddingRight: 10, paddingTop: 15}}>
						
						<div style={{flex:1,justifyContent:"center"}}>
							<span style={{textAlign:"center",color:foreground}}>{description}</span>
						</div>
				</div>
			</div>
		)
	}
}