import React, { Component } from 'react';
import './index.css';

export default class HProgressBar extends Component {
	render() {
        const {progress, width} = this.props;
        return (
            <div className="progressBarSki"  style={{ width }}>
                <div className="percentagem" style={{ width: progress + "%" }}></div>
            </div>
        )
    }
}