import React, { Component } from 'react';
import Chevron from './chevron'; 
import Face from '../../images/face.png'
import {Style} from './style'
import HProgressBar from '../../components/hprogressbar';

export default class HeaderComponent extends Component {
	
	constructor(props){
		super(props);
	}

	render() {
		const{progress,hideProgress} = this.props;

		return (
			<div>
				<div style={{backgroundColor:"#0072FF"}}>
					<div style={{paddingTop:24, display: "flex", marginLeft:10, marginRight:10, flexDirection: 'row',justifyContent: 'space-between'}}>
						<Chevron onPress={this.props.back} type="backWhite"/>
						<img src={Face} style={Style.face}/>
						<div />
					</div>
				</div>
				{ hideProgress?null:(
					<div style={{alignItems:"center",marginTop:20, marginLeft: 25, marginRight:25}}>
						<HProgressBar progress={progress*100}   color={"#007CFF"}/>
					</div>
				)}
			</div>
		);
	}

}