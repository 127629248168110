import * as Immutable from 'immutable';
import { createReducer } from 'redux-act';
import { createAction } from 'redux-act';

export const getInterviews = createAction("Interviews");
export const getInterviewsResponse = createAction("Interviews response");
export const getQuestions = createAction("Questions");
export const getQuestionsResponse = createAction("Questions response");
export const setAnswer = createAction("Set Answer");
export const setAnswers = createAction("Set Answers");
export const saveAnswers = createAction("Save answers");
export const saveAnswersResponse = createAction("Save answers response");
export const getAnswers = createAction("Get answers from API");
export const getFeedback = createAction("Feedback");
export const getFeedbackResponse = createAction("Feedback response");
export const getScoreResponse = createAction("Score response");
export const selectInterview = createAction("Select Interview");
export const setInterview = createAction("Set Interview");
export const validateInvite = createAction("Validate invite");
export const validateInterview = createAction("Validate interview");
export const setInvite = createAction("Set invite");
export const none = createAction("none");
export const createViaInvite = createAction("Create via invite");
export const updateAvatarResponse = createAction("Update avatar reponse");
export const updateAvatar = createAction("Update avatar");
export const updateProfile = createAction("Update profile")
export const uploadVideo = createAction("Upload video");
export const PERSONALITY_RESPONSE =createAction("PERSONALITY_RESPONSE")
const INITIAL =Immutable.fromJS({
	loadingInterviews:false,
	interviews:[],
	loadingQuestions:false,
	questions:{},
	answers:{},
	savingAnswers:false,
	loadingCandidates:false,
	candidates:[],
	loadingFeedback:false,
	feedback:[],
	selectedInterview:{
		
	},
	invite:"",
	score: 0,
	personalityText: null
});

const ACTIONS = {
	[setInterview]: (data,payload)=>{		
			return data.set('selectedInterview',Immutable.fromJS(payload))
	},	
	[getInterviewsResponse]: (data,res)=>{
		return data
			.set('loadingInterviews', false)
			.set('interviews',Immutable.fromJS(res));
	},
	[getQuestionsResponse]:(data,res) =>{
		return data
			.set('loadingQuestions', false)
			.set('questions',Immutable.fromJS(res));
	},
	[setAnswer]:(data,payload) =>{
		return data.setIn(['answers',payload.answer.competencyId,payload.answer.questionId],
											Immutable.fromJS(payload.answer));
	},
	[setAnswers]:(data,payload) =>{
		return data.setIn(['answers'], Immutable.fromJS(payload.answers));
	},
	[saveAnswersResponse]: (data) =>{
		return data
					.set('savingAnswers', false)
	},
	[getFeedbackResponse]: (data,res) =>{
		return data
			.set('loadingFeedback', false)
			.set('feedback',Immutable.fromJS(res));
	}	,
	[setInvite]: (data,invite) =>{
		return data
			.set('invite', invite)
	},
	[getScoreResponse]: (data, score) =>{
		return data.set('score',score)
	},
	[PERSONALITY_RESPONSE]: (data, text) =>
		data.set("personalityText", text)
}
const REDUCER = createReducer(ACTIONS, INITIAL);

export default REDUCER;