
import { put, takeLatest } from 'redux-saga/effects'

import {REQUEST_PROFILE, READ_CONFIG} from '../reducers/profile'
import {REQUEST_CONFIG} from '../reducers/vacancy'

export function* requestProfile() {
    const newState = sessionStorage.getItem("vacancy")
    try{
        if(newState){
            const newVacancy = JSON.parse(newState);
            yield put(READ_CONFIG(newVacancy))
            yield put(REQUEST_CONFIG({
                ceo_level: newVacancy.config.ceo_level,
                scope: newVacancy.config.scope,
                area: newVacancy.config.area
            }))		
        }
    }catch(e){

    }
}

export function* watchRequestProfile() {
    yield takeLatest( REQUEST_PROFILE.getType() , requestProfile);
}
  