/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 * @flow
 */

import React, { Component } from 'react';
import {Style} from './style';
import chevronImage from '../../images/chevron.png';
import chevronWhiteImage from '../../images/chevronWhite.png';

import closeImage from '../../images/close.png';

export default class Chevron extends Component {

	constructor(props) {
		super(props);
    this.onPress = this.onPress.bind(this);
	}

  render() {
    const {title,type} = this.props;
    return (
      <div style={type =="back" || type =="backWhite"? Style.chevron: Style.chevronClose} onClick={this.onPress} >
        {type =="back"?(
         <img src={chevronImage} style={Style.chevron} />
        ):null}
        {type =="backWhite"?(
         <img src={chevronWhiteImage} style={Style.chevron} />
        ):null}
        {type =="close"?(
         <img src={closeImage} style={Style.chevronClose}/>
        ):null}
        
      </div>
    );
  }
  onPress() {
    if(this.props.onPress){
      this.props.onPress();
    }
  }
}