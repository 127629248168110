import React, { Component } from 'react';
import './index.css';
import calibrate from '../../images/calibrate.svg';
export default class HCalibrate extends Component {

	constructor(props){
		super(props);
		this._move = this._move.bind(this);
	}
	render() {
		const {initial,final,progress,description} = this.props;
		return (
			<div className="calibrate">
				<div>
					{description}
				</div>
				<div ref="bar" className="bar" onMouseDown={this._move} onMouseMove={this._move} onTouchMove={this._move}>
					<div className="progress" style={{flex:progress}}>
						<div className="indicator">
							<img src={calibrate}/>
						</div>
					</div>
					<div className="missing" style={{flex:100-progress}}>
					</div>
				</div>
				<div className="labels">
					<div>
						{initial}
					</div>
					<div>
						{final}
					</div>
				</div>
			</div>
		)
	}
	_move(e) {
		if(this.props.onChange && (e.buttons>0 || (e.touches && e.touches.length >0))) {
			let rect = this.refs.bar.getBoundingClientRect();
			let xClient = e.clientX || e.touches[0].clientX;
			let xPosition = xClient - rect.x;
			let newProgress = (xPosition/rect.width*100) + 2;
			if(newProgress>100){
				newProgress=100;
			}
			let newValue = 0;
			if(this.props.steps){
				let delta = 100 / this.props.steps;
				let n = 0;
				while(n<newProgress- (delta /2)){
					n+=delta;
					newValue++;
				}
				newProgress =n;
			}
			if(newProgress>100){
				newValue=this.props.steps;
			}
			this.props.onChange(newValue,this.props.payload);
		}
	}
}