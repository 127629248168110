import { createAction, createReducer } from 'redux-act';
import Inmutable from 'immutable';
const SAVE_CONFIG = createAction("SAVE_CONFIG");
const READ_CONFIG = createAction("READ_CONFIG");
const REQUEST_PROFILE = createAction("REQUEST_PROFILE");
const REQUEST_INITIALIZE = createAction("REQUEST_INITIALIZE");
const initialState = Inmutable.fromJS({
  vacancy:{
    section:0,
    config:{
      ceo_level: 3,
      area: 'hr',
      scope: 'local',
    },
    confidential: true
  },
  initialized: false
})

export default createReducer({
    [SAVE_CONFIG]: (state, payload) => {
      console.log("Saving to Sesstion storage", state)
      const newState = state.set("vacancy", state.get("vacancy").mergeDeep( Inmutable.fromJS(payload)))
      sessionStorage.setItem("vacancy", JSON.stringify(newState.get("vacancy").toJS()))
      return newState
    },
    [READ_CONFIG]: (state, payload) => state.set("vacancy", Inmutable.fromJS(payload)),
    [REQUEST_INITIALIZE]: (state) => state.set("initialized", true)
},initialState);
  
export {SAVE_CONFIG, READ_CONFIG, REQUEST_PROFILE, REQUEST_INITIALIZE};
