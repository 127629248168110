import React, { Component } from 'react';
export default class AnswerSelector extends Component{

    constructor(props){
        super(props);
        this.state ={
            index: null
        }
    }
    render() {
        const {children} = this.props;
        return (
            <div style={{cursor:"pointer",display:"flex", flexDirection: "column", alignItems:'center'}}>                
                {children.map((C,i)=> (
                    <div key={i} style={{marginBottom:20, textAlign:"center"}} onClick={this.onChange.bind(this, i)}>
                        {C}
                    </div>
                ))}
            </div>
        )
    }
    onChange(index){
        this.props.afterChange(index)
    }
}