import React, { Component } from 'react';
import './index.css';
import toggleOn from '../../images/toggleOn.svg';
import toggleOff from '../../images/toggleOff.svg';

export default class HToggle extends Component {
	constructor(props){
		super(props);
		this._toggle = this._toggle.bind(this);
	}

	render() {
		const {label,enabled} = this.props; 
		const image = enabled ? toggleOn : toggleOff;
		return (
			<div className="toggle">
				<div className="toggle-label">
					{label}
				</div>
				<div className="toggle-check" onClick={this._toggle}>
					<img src={image}/>
				</div>
			</div>
		)
	}

	_toggle() {
		if(this.props.onChange){
			this.props.onChange(!this.props.enabled, this.props.payload);
		}
	}

}