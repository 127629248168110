import React, { Component } from 'react';

import {Style} from './style';
import HButton from '../../components/hbutton';
import Header from './header';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import Speech from '../../images/speech.png'
import Headphones from '../../images/headphones.png'
import Loader from './loader'
import {ToastContainer, toast } from 'react-toastify';
import VideoRecorder from 'react-video-recorder'


const TIMEOUT_PREVIEW = 10;
const TIMEOUT_RECORD = 120;
/*
	States
	0 Inital state
	1 Show camera and start timer, the timer should start the recording
	2 Recording state
*/

export default class QuestionVideo extends Component{
	constructor(props) {
		super(props);
		this._next = this._next.bind(this);
		this._back = this._back.bind(this);
		this._view = this._view.bind(this);
		this._cancel = this._cancel.bind(this);
		this._submit = this._submit.bind(this);
		this.statusChange = this.statusChange.bind(this);
		this.cameraReady = this.cameraReady.bind(this);
		this.mountError = this.mountError.bind(this);
		this.prepareVideo = this.prepareVideo.bind(this);
		this.state = {
			step: 0,
			timer1:0,
			timer2:0,
			loading:false,
			showPreview: false,
			videoBlob: null,
			progressUpload:0,
			cameraError: false,
			cameraPermission: true,
			timeRecording: 0
		}
	}
	render() {
		const{question,progress,answers}= this.props;
		const{step,timer1,timer2,videoBlob, showPreview, progressUpload} = this.state;

		return (
			<div style={{flex:1, height:"100%", display:"flex", flexDirection:"column",backgroundColor:"white"}}>
			<ToastContainer 
			
							position="top-right"
							autoClose={5000}
							hideProgressBar={false}
							newestOnTop={false}
							toastClassName="dark-toast"
							progressClassName='transparent-progress'						
							closeOnClick
							pauseOnHover
						/>
				<Loader loading={this.state.loading} progress={progressUpload} onCancel={this._cancel} />
					
				<Header progress={progress} hideProgress={step>0} back={this._back}>
					
				</Header>
				<div>

				</div>
				{step ==0?(
					[
						<div key="0" style={{display:"flex", justifyContent:"center",marginTop:20,alignItems:"center",marginLeft:20,marginRight:20}}>
							<span style={{...Style.blackText,textAlign:"center",fontSize:18,fontWeight:"500"}}>{question ? question.getIn(['questions',this.props.lastAnswer]): ''}</span>
						</div>,
						<div key="1" style={{marginTop:20,alignItems:"center", display:"flex", flexDirection:"column"}}>
							<div style={{alignItems:"center",marginTop:20}}>
								<img src={Speech} style={{width:10,height:20,width:25,objectFit: "contain"}}  />
							</div>
							<span style={{...Style.center,...Style.blackText,marginTop:20,fontSize:16,marginLeft:10,marginRight:10}}>
								Record your message, be concise and as clear as possible in the video answer. You only have one opportunity to record your message.
							</span>
							<div style={{alignItems:"center",marginTop:20}}>
								<img src={Headphones} style={{width:10,height:20,width:25,objectFit: "contain"}} />
							</div>
							<span style={{...Style.center,...Style.blackText,fontSize:16,marginLeft:10,marginRight:10,marginTop:20}}>
								Do not forget to use your headphones with integrated microphone.
							</span>
						</div>,
						<div style={{...Style.horizontalCenterAlign,position:"absolute",bottom:10, width: "100%"}}>
							<div style={{width:320, margin:"0 auto"}}>
								<HButton type="transparent"  onClick={this._next}>Next</HButton>
							</div>
						</div>
					]
				):null}
				{step >=1?(
					<div style={{display:"flex", flex:"auto", position:"relative"}}>
						
						<div style={{position:"absolute", top:0, right:0, display:"flex", flex:"auto", width:"100%", height:"100%",flexDirection:"column",justifyContent:"space-between",alignItems:"center"}}>
							<div style={{marginTop:10,marginLeft:20,marginRight:20,zIndex:99}}>
								{step ==1?(
									<div style={{backgroundColor: "rgba(0,0,0,0.7)",borderRadius:100,textAlign:"center"}}>
										<span style={{...Style.whiteText,marginRight:20,marginLeft:20,marginTop:10,marginBottom:10,fontSize:14, display:"flex", alignItems:"center"}}>
											Try to center your face in the camera.
										</span>
									</div>
								):null}
								{step ==2?(
									<div style={{backgroundColor: "rgba(0,124,255,0.7)",borderRadius:100}}>
										<span style={{...Style.whiteText,marginRight:20,marginLeft:20,marginTop:10,marginBottom:10,fontSize:14, zIndex:99}}>
											{question ? question.getIn(['questions',this.props.lastAnswer]): ''}
										</span>
									</div>
								):null}
							</div>
							
							{step <= 1?(
								<div>
									<span style={{...Style.whiteText,fontSize:32}}></span>
								</div>
							):null}
							
							{step ==2?(
								<div style={{flexDirection:"row", display:"flex", justifyContent:"flex-end",width:"100%", marginBottom:95, marginRight:40}}>
									<div style={{width:100,height:50, marginLeft:20, top:10, zIndex:99}}>								
										<HButton type="primary"  onClick={this._submit} disabled={videoBlob==null}>
											Submit
										</HButton>
									</div>
								</div>
							):null}
						</div>
						<VideoRecorder
							isOnInitially={true}
							timeLimit={60000}
							countdownTime={10000}
							onTurnOnCamera={()=> null}
							onTurnOffCamera={()=> null}
							onStartRecording={()=> null}
							onStopRecording={()=> null}
							onRecordingComplete={this.prepareVideo}
							onOpenVideoInput={()=> null}
							onStopReplaying={()=> null}
							onError={()=> null}
						>
						
						</VideoRecorder>
					</div>
					
				):null}

				{/*step >=1?(
					<RNCamera ref="cam" onStatusChange={this.statusChange} onCameraReady={this.cameraReady} onMountError={this.mountError} type={RNCamera.Constants.Type.front} style={{flex:1,justifyContent: 'space-between',alignItems: 'center'}}  >
						<div style={{marginTop:10,marginLeft:20,marginRight:20}}>
							{step ==1?(
								<div style={{backgroundColor: "rgba(0,0,0,0.7)",borderRadius:100}}>
									<span style={[Style.whiteText,{marginRight:20,marginLeft:20,marginTop:10,marginBottom:10,fontSize:14}]}>
										Try to center your face in the camera.
									</span>
								</div>
							):null}
							{step ==2?(
								<div style={{backgroundColor: "rgba(0,124,255,0.7)",borderRadius:100}}>
									<span style={[Style.whiteText,{marginRight:20,marginLeft:20,marginTop:10,marginBottom:10,fontSize:14}]}>
										{question ? question.getIn(['questions',this.props.lastAnswer]): ''}
									</span>
								</div>
							):null}
						</div>
					
						{step == 1?(
							<div>
								<span style={[Style.whiteText,{fontSize:32}]}>
									{ TIMEOUT_PREVIEW - timer1 }
								</span>
							</div>
						):null}
						
						{step <= 1?(
							<div>
								<span style={[Style.whiteText,{fontSize:32}]}></span>
							</div>
						):null}
						
						{step ==2?(
							<div style={{flexDirection:"row", justifyContent:"space-between"}}>
								<div style={{width:100,height:50, marginRight:20, top:10}}>
									<HButton type="primary" title="View" onPress={this._view} disabled={videoUri==null}/>
								</div>
								<div onClick={()=> this.restartTimer()} style={{width: "64px",}}>
									<CircularProgressbarWithChildren
										styles={buildStyles({
											// Text size
											textSize: '16px',
											// Can specify path transition in more detail, or remove it entirely
											// pathTransition: 'none',
										
											// Colors
											pathColor: `#7ED321`,
											textColor: '#fff',
											trailColor: '#FF0000',
											backgroundColor: '#B1B1B1',
										})}
									  	value={timer2}
										size={64}
										width={6}
										fill={timer2}
										rotation={0}
										tintColor="#FF0000"
										fillColor="#B1B1B1"
										backgroundColor="#7ED321" >
										{timer2 > 0 ? 
											<div style={{flexDirection: "row", top:-64}}>
												<span style={{color:"#FFFFFF",fontSize:10, position:"absolute", top:19, left: 0, textAlign:"center", width:64}}>
													{`${this.state.timeRecording}s`}												
												</span>
												<span style={{color:"#FFFFFF",fontSize:10, position:"absolute", top:34, left: 0,textAlign:"center", width:64}}>
													Stop
												</span>
											</div>
										:null}
									</CircularProgressbarWithChildren>
							 </div>
							 <div style={{width:100,height:50, marginLeft:20, top:10}}>								
							 	<HButton type="primary" title="Submit" onPress={this._submit} disabled={videoUri==null}/>
							 </div>
							</div>
						 ):null}
					</RNCamera>
				):null*/}
			</div>
		)
	}
	_back() {
		switch(this.state.step){
			case 0:
				return this.props.back();
			case 1:
				clearInterval(this.timer);
				this.setState({
					step:0,
					timer1: 0
				});
				break;
			case 2:
				this._stopRecord();
				this.setState({
					step:0,
					timer2: 0
				});
		}
	}	
	_stopRecord() {
		if(this.state.timeRecording< 20){
			return toast("The video should last 20 seconds at least", 1000);
		}
		clearInterval(this.clock);
		this.clock = 0;
		this.refs.cam.stopRecording();
	}

	_next() {
    //this.props.next();
    this.setState({
    	step:2
    });
	/*let initTime = new Date().getTime();
    this.timer =setInterval(()=>{
			let newTime = new Date().getTime();
			let seconds = Math.round( (newTime - initTime) / 1000);
			this.setState({
				timer1: seconds
			});
			if(seconds>=TIMEOUT_PREVIEW){
				clearInterval(this.timer);
				this.setState({
					step:2
				})
				this.startTimer();
			}
		},500);*/
	}
	prepareVideo(blob) {
		this.clock = 0;
		console.log("Trying to upload");
		this.setState({
			videoBlob: blob
		})
	}
	_cancel() {
		this.setState({
			loading:false
		})
		if(this.request){
			try{
				this.request.abort()
			}catch(e) {
				console.log("Error canceling request");
			}
		}
	}
	_submit() {
		if(this.state.videoBlob) {
			this.setState({
				loading:true
			},()=>{
				this.props.uploadVideo({
					videoId: this.props.question.get("questionId"),
					video: this.state.videoBlob,
					type: "video",
					callback:(result)=>{
						this.setState({
							loading:false
						})
						if(result){
							if(this.props.question.get("competencyId")== "KQ") {
								this.props.setAnswer({
									answer: {
										text: "",
										competencyId: this.props.question.get("competencyId"),
										questionId: this.props.question.get("questionId")
									}
								});
							}
							this.props.next();
						}else{
							toast("There was an error uyploading your answer, please try again", 1000);
						}
						//resolve(result);
					},
					onUploading: (request)=>{
						this.request = request
					},
					onProgress:(percent)=>{
						this.setState({
							progressUpload: Math.round(percent)
						})
					}
				});
			});
		}else{

		}
	}
	_view() {
		if(this.clock!=0){
			toast("The video is still recording, please stop your recording and try again.", 1000);
			return;
		}
		this.setState({showPreview:true});
	}
	restartTimer() {
		if(this.clock==0){
			this._next();
		}else{
			this._stopRecord();
		}
	}

	statusChange(cameraStatus, recordAudioPermissionStatus) {
		if(cameraStatus === 'READY' && recordAudioPermissionStatus === 'AUTHORIZED') {
			this.setState({cameraPermission: true})
		}else{
			this.setState({cameraPermission: false})
		}
	}
	mountError(){
		this.setState({cameraError: true})
	}
	cameraReady(){
		this.setState({cameraReady: true})
	}
/*
	startTimer() {
		if(this.state.cameraReady && this.state.cameraPermission && !this.state.cameraError){
			if(!this.clock){
				console.log("Start recording")
				let start = new Date().getTime();
				this.refs.cam.recordAsync({
					quality: RNCamera.Constants.VideoQuality["480p"],
				})
				.then(this.prepareVideo)
				this.clock = setInterval(()=>{ 
					let time = (new Date().getTime() - start) /1000;
					if(time>=TIMEOUT_RECORD){
						console.log("Stoping record")
						this.refs.cam.stopRecording();
						clearInterval(this.clock);
						return //this.props.next();
					}
					this.setState({
						timer2: time * 100 / TIMEOUT_RECORD,
						timeRecording: Math.round(time)
					});
				}, 500);
			}
		}else{
			toast("Herman can't initialize the camera, please check your permissions.", 1000);
		}
	}*/

}