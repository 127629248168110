import React, { Component } from 'react';
import './index.css';
export default class HInput extends Component {

	constructor(props){
		super(props);
		this.onChange = this.onChange.bind(this);
	}

	render() {
		const {children,placeholder,type,name,className,value} = this.props; 
		return (
			<input 
				className={`hinput ${className}`} 
				placeholder={placeholder}
				type={type} 
				name={name}
				value={value}
				onChange={this.onChange}>

			</input>
		)
	}
	onChange(ev) {
		this.props.onChange(ev.target.value,this.props.stateId)
	}
}