  export const BACKGROUND="#f8f8f8";
  export const WHITE="#FFFFFF";
  export const BLACK="#4A4A4A";
  export const GRAY="#9B9B9B";
  export const RED="#FF0000";
  export const PRIMARY = "#0080FF";
  export const SECONDARY = "#001FFF";
  export const TERNARY ="#0072FF";
  export const LINKED ="#0081C1";
  export const DANGER="#FF2E89";
  export const PRIMARY_FONT ="sf_compact_display";
  export const SECONDAY_FONT ="Dosis";
  
  export const Style = {
      avatar:{
          width:50,
          height:50
      },
      center:{
          textAlign:"center",
      },
      topBar:{
          flexDirection:"row",
          marginTop:30,
          height:26,
      },
      topLogo:{
          flex:1,
          width:null,
          height:26
      },
      chevron:{
          width:13,
          height:26,
          objectFit: "contain"	
      },
      chevronClose:{
          width:26,
          height:26,
          objectFit: "contain"	
      },
      full:{
          flex:1,
          width:null
      },
      backgroundLogin:{
          flex: 1,
          objectFit: 'cover',
          width:null,
          height:null
      },
  
      logoIntro:{
          width:null,
          flex:1,
          height:75,
          marginRight:25,
          marginLeft:25,
          marginTop:50
      },
      loginSwiper:{
  
      },
      loginContainer:{
          flex:1,
          backgroundColor: WHITE
      },
      startContainer:{
          flex:1,
          justifyContent:"space-between",
          paddingLeft:20,
          paddingRight:20,
      },
      loginOr:{
          flexDirection:"row",
          backgroundColor: "transparent",
          marginTop:10,
          marginBottom:10
      },
      loginOrWhite:{
          flex:1,
          flexDirection:"row",
          backgroundColor: "transparent",
          marginTop:10,
          marginBottom:20
      },
      font:{
          fontFamily: PRIMARY_FONT,
      },
      link:{
          fontFamily: PRIMARY_FONT,
          color:GRAY,
          fontWeight:"100",
          fontSize:16,
          textDecorationLine: 'underline'
      },
      disclaimer:{
          fontFamily: PRIMARY_FONT,
          color:GRAY,
          fontSize:12
      },
      disclaimerLink:{
          fontFamily: PRIMARY_FONT,
          color:GRAY,
          fontSize:12,
          textDecorationLine: 'underline',
          fontWeight:"bold"
      },
      col40:{
          flex:40
      },
      col10:{
          flex:10
      },
      col20:{
          flex:20
      },
      whiteDotSwipe:{
          backgroundColor: WHITE
      },
      modalBackground:{
          flex:1,
          justifyContent: 'center',
      padding: 20,
          backgroundColor:"rgba(0, 0, 0, 0.5)",
      },
      modalContainer:{
          width:null,
          height:400,
          borderRadius:5,
      backgroundColor:WHITE
      },
      titleModal:{
          fontFamily: PRIMARY_FONT,
          color:TERNARY,
          backgroundColor:'transparent',
          textAlign:"center",
          fontSize: 20,
          fontWeight:"600",
          marginTop:20
      },
      textModal:{
          fontFamily: PRIMARY_FONT,
          color:BLACK,
          backgroundColor:'transparent',
          textAlign:"center",
          fontSize: 16,
          fontWeight:"100",
          marginTop:5,
          marginBottom:35
      },
      modalList:{
          flexDirection:"row",
          justifyContent:"flex-start",
          marginBottom:20
      },
      modalListIcon:{
          height:20,
          width:25,
          top:10,
          marginLeft:15
      },
      modalListTextBlock:{
          left:10,
      },
      modalListText:{
          fontFamily: PRIMARY_FONT,
          color:BLACK,
          fontSize: 16,
          fontWeight:"100"
      },
      modalSmallTextBold:{
          fontFamily:PRIMARY_FONT,
          color: PRIMARY,
          fontSize:13,
          fontWeight:"bold"
      },
      modalSmallText:{
          fontFamily:PRIMARY_FONT,
          color: PRIMARY,
          fontSize:13
      },
      buttonModal:{
          height:40,
          flex:1,
          flexDirection:"row",
          marginLeft:10,
          marginRight:10
      },
      calendar:{
          height:20,
          width:20,
          top:-4,
          left:5
      },
      greeting:{
          fontFamily: PRIMARY_FONT,
          color:TERNARY,
          backgroundColor:'transparent',
          textAlign:"center",
          fontSize: 20,
          marginTop:35,
          fontWeight:"600"
      },
      greeting2:{
          fontFamily: PRIMARY_FONT,
          color:BLACK,
          backgroundColor:'transparent',
          textAlign:"center",
          fontSize: 17,
          fontWeight:"100",
          marginTop:5,
          marginBottom:30
      },
      whiteText:{
                  fontFamily: PRIMARY_FONT,
          color: WHITE,
          backgroundColor:'transparent'
      },
      blackText:{
                  fontFamily: PRIMARY_FONT,
          color: BLACK,
          backgroundColor:'transparent'
      },
      centerText:{
          textAlign:"center"
      },
      smallText:{
          fontSize:14,
          fontFamily: PRIMARY_FONT
  
      },
      boldText: {
          fontWeight:"bold"
      },
      face:{
          width:40,
          height:40,
          marginTop:-10,
          marginBottom:20,
          objectFit: "contain"	
      },
      tutorialBackground:{
          flex:1,
          backgroundColor: TERNARY
      },
      marginBottom:{
          marginBottom:10
      },
      intro:{
          position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
      },
      horizontalCenterAlign:{
        display: "flex",
        flexDirection:"row",
        justifyContent: "center"
      },
      horizontalDefaultAlign:{
          flex:1,
          flexDirection:"row",
      },
      horizontalSeparator:{
          flex:1
      },
      bottomFixedButtons:{
          position:"absolute",
          bottom:0
      },
      buttonViewDefault:{
          flex:10,
          height:40,
          width:null,
          borderRadius: 10
      },
      buttonViewPrimary:{
          backgroundColor:PRIMARY,
          height:40,
          justifyContent:"center",
          borderRadius: 10,
      },
      buttonViewSecondary:{
          backgroundColor: SECONDARY,
          height:40,
          justifyContent:"center",
          borderRadius: 10
      },
      buttonViewLinked:{
          backgroundColor: LINKED,
          height:40,
          justifyContent:"center",
          borderRadius: 10
      },
      buttonViewTransparent:{
          backgroundColor: "transparent",
          height:40,
          justifyContent:"center",
          borderRadius: 10,
          borderColor: "#0077FF",
          borderWidth: 1
      },
      buttonViewTransparent2:{
          backgroundColor: "transparent",
          height:40,
          justifyContent:"center",
          borderRadius: 10,
          borderColor: "#FFFFFF",
          borderWidth: 1
      },
      buttonViewLink:{
          backgroundColor: "transparent",
          height:40,
          justifyContent:"center"
      },
      buttonTextPrimary:{
          backgroundColor:"transparent",
          color: WHITE,
          fontSize:16,
          textAlign:"center",
          fontFamily: PRIMARY_FONT,
      },
      buttonTextLink:{
          backgroundColor:"transparent",
          color: PRIMARY,
          fontSize:14,
          textAlign:"center",
          fontWeight: "bold",
          fontFamily: PRIMARY_FONT
      },
      buttonTextLink2:{
          backgroundColor:"transparent",
          color: WHITE,
          fontSize:14,
          textAlign:"center",
          fontWeight: "bold",
          fontFamily: PRIMARY_FONT
      },
      buttonIcon:{
          height:20,
          width:20,
          position:"absolute",
          left:15
      },
      selectField:{
          height: 40, 
          borderColor: GRAY, 
          borderWidth: 1,
          borderRadius: 5,
          paddingLeft:10
      },
      selectFieldValid:{
          height: 40, 
          borderColor: PRIMARY, 
          borderWidth: 1,
          borderRadius: 5,
          paddingLeft:10
      },
      selectFieldInvalid:{
          height: 40, 
          borderColor: DANGER, 
          borderWidth: 1,
          borderRadius: 5,
          paddingLeft:10
      },
      textField:{
          color:BLACK,
          fontFamily: PRIMARY_FONT,
          fontSize:16
      },
      textFieldFix:{
          top:8,
          height:20,
          marginLeft:10
      },
      textFieldValid:{
          color:PRIMARY,
          fontFamily: PRIMARY_FONT,
          fontSize:16
      },
      textFieldInvalid:{
          color:DANGER,
          fontFamily: PRIMARY_FONT,
          fontSize:16
      },
      textFieldInfo:{
          height:20,
          width: 30,
          top:8
      },
      textFieldIcon:{
          height:20,
          position:"absolute",
          width: 20,
          right: 15,
          top:8
      },
      sideProfile:{
          alignItems:"center",
          marginBottom:20
      },
      textPrimary:{
          color:BLACK
      },
      main:{
          flex:1,
          height:300,
          backgroundColor:"#000000"
      },
      titlebar:{
          height:56,
          shadowRadius: 1,
      shadowOffset: { width: 0, height: 0.5 },
      shadowOpacity: 0.4,
      shadowColor: 'black',
      paddingTop:15,
      backgroundColor:"#0080f7",
      flexDirection:"row",
      justifyContent:"space-between"
      },
      titlebarIcon:{
          fontSize:20,
          top:10,
          marginLeft:12
      },
      titlebarText:{
          fontSize:18,
          paddingTop:7,
          fontWeight: "600",
          width:200,
          color:WHITE,
          textAlign:"center",
          fontFamily: SECONDAY_FONT
      },
      container:{
          flex:1,
          backgroundColor: BACKGROUND,
          fontFamily: PRIMARY_FONT
      },
      btn_center:{
          justifyContent: 'center',
          height:50
      },
  
      sideMenuContainer:{
          paddingTop:20,
          flex:1,
      },
      logoText:{
          fontSize:30,
          textAlign:"center",
          paddingTop:50,
          paddingBottom:50,
      },
      btnText:{
        color: 'white',
        fontWeight: 'bold'
      },
      btn:{
          shadowRadius: 1,
      shadowOffset: { width: 0, height: 0.5 },
      shadowOpacity: 0.4,
      shadowColor: 'black',
      elevation: 4,
      flexDirection:"row",
      marginBottom:20,
      marginTop:20
      },
      textfield: {
      height: 28,  // have to do it on iOS
      marginTop: 32,
    },
    textfieldWithFloatingLabel: {
      height: 48,  // have to do it on iOS
      marginTop: 10,
    },
      btnSideMenu:{
          alignItems: "flex-start",
          justifyContent:"flex-start",
          flexDirection:"row",
          borderTopWidth:1,
          borderTopColor:BACKGROUND
      },
      btnCard:{
          width:75,
          textAlign:"right"
      },
      btnCardLong:{
          width:100,
      },
      btnBigAnswer:{
          flex:1,
      },
      textSideButton:{
          textAlign:"left",
          paddingTop:2
      },
      cardStyle:{
          marginBottom:20,
          marginLeft:15,
          marginRight:15,
          marginTop:15,
          backgroundColor: "#fff",
      borderRadius: 2,
      shadowColor: "#000000",
      shadowOpacity: 0.3,
      shadowRadius: 1,
      shadowOffset: {
        height: 1,
        width: 0.3,
      }
      },
      cardContentStyle:{
          padding: 15,
      },
      cardTitleStyleWhite:{
          color:WHITE
      },
      cardActionStyle:{
          alignItems:"flex-end",
          flexDirection:"row",
          justifyContent:"flex-end",
          height:65
      },
      cardRow:{
          flexDirection:"row"
      },
      strong:{
          fontWeight:"bold",
      },
      cardRowTitle:{
          fontWeight:"bold",
          width:100
      },
      icon:{
          color:WHITE,
          marginRight:5
      },
      iconBlack:{
          color:BLACK,
          marginRight:5
      },
      iconSideButton:{
          fontSize:20,
          width:25
      },
      interview:{
          flex:1,
          marginBottom:15,
          marginLeft:15,
          marginRight:15,
          marginTop:15
      },
      interviewMain:{
          flex:9
      },
      interviewQuestion:{
          flex:1
      },
      interviewAnswers:{
      },
      progressInterview:{
          paddingTop:15,
      },
      interviewQuestionHeader:{
  
      },
      simpleQuestion:{
          flex:1
      },
      simpleQuestionTitle:{
          fontSize:20,
          textAlign:"center",
          paddingTop:15
      },
      simpleQuestionAnswers:{
          flex:1,
          justifyContent:"space-around"
      },
      simpleQuestionRow:{
          flexDirection:"row",
          justifyContent:"space-around",
          alignItems:"stretch",
          flex:1,
      },
  
      simpleQuestionAnswer:{
          flex:1,
          justifyContent:"space-around",
          alignItems:"stretch"
      },
      simpleQuestionImage:{
          width:150,
          height:150,
          justifyContent:"center",
          paddingLeft:10
      },
      simpleQuestionAnswerText:{
          textAlign:"center",
          fontSize:10
      },
      listrow: {
      flexDirection: 'row',
      justifyContent: 'flex-start',
      padding: 10,
      backgroundColor: '#F6F6F6',
    },
    listtext:{
        flex:1
    },
    listtextmulti:{
        flex:1,
        paddingLeft:10	
    },
    listicon:{
        color:BLACK,
        fontSize:40,
    },
    points: {
      backgroundColor: 'transparent',
      position: 'absolute',
      top: 72,
      left: 56,
      width: 90,
      textAlign: 'center',
      color: '#7591af',
      fontSize: 50,
      fontWeight: "100"
    },
    distribute:{
        justifyContent:"space-between"
    }
  };